header {
  .menu__btn {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 82px;
    right: 148px;
    width: 81px;
    height: 81px;
    background-color: $dark;
    cursor: pointer;
    z-index: 1046;
    border-radius: 50%;
  }

  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: block;
    position: absolute;
    width: 50%;
    height: 3px;
    background-color: $white;
    border-radius: 3px;
    transition-duration: .25s;

    &:hover {
      background-color: $dark;
    }
  }

  .menu__btn > span::before {
    width: 100%;
    content: '';
    top: -8px;
  }

  .menu__btn > span::after {
    width: 100%;
    content: '';
    top: 8px;
  }

  #menu__toggle {
    display: none;
  }

  #menu__toggle:checked ~ .menu__btn {
    transition-duration: .4s;
    background-color: transparent;
  }

  #menu__toggle:checked ~ .menu__btn > span {
    transform: rotate(45deg);
  }

  #menu__toggle:checked ~ .menu__btn > span::before {
    top: 0;
    transform: rotate(0);
  }

  #menu__toggle:checked ~ .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
  }
}

@media (max-width: 1400px) {
  header {
    .menu__btn {
      top: 50px;
      right: 80px;
    }
  }
}

@media (max-width: 1024px) {
  header {
    .menu__btn {
      top: 45px;
      right: 45px;
      width: 70px;
      height: 70px;
    }
  }
}

@media (max-width: 768px) {
  header {
    .menu__btn {
      width: 60px;
      height: 60px;
    }
  }
}

@media (max-width: 576px) {
  header {
    .menu__btn {
      top: 30px;
      right: 30px;
      width: 60px;
      height: 60px;
    }
  }
}
