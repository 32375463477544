.name_page_wrapper .contacts_page {
  background-image: url("../img/background-contacts.jpg");
}

.aqwi-map {
  position: relative;

  .map {
    width: 100%;
    height: 800px;

    iframe {
      height: 800px;
    }

    .mapTitle {
      position: absolute;
      z-index: 1000;
      width: 270px;
      font-size: 14px;
      line-height: initial;
      padding: 5px;
      border: 1px solid black;
      border-radius: 10px;
      background: white;
    }
  }

  .aqwi-map-info {
    position: absolute;
    right: 50%;
    bottom: 0;
    left: 50%;
    width: 1100px;
    padding: 3rem;
    background-color: $white;
    transform: translate(-50%, 0);

    li {
      margin-bottom: 1rem;

      &.address {
        display: flex;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .icon {
        margin-right: 1rem;

        img {
          width: 50px;
          height: 50px;
        }
      }
    }

    a {
      color: $dark;
    }
  }
}

@media (max-width: 1600px) {
  .aqwi-map {
    .aqwi-map-info {
      width: 750px;
      font-size: 26px;
      line-height: 30px;
      padding: 2rem 3rem;

      li {
        margin-bottom: 0.75rem;

        .icon img {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .aqwi-map {
    .aqwi-map-info {
      width: 950px;
    }
  }
}

@media (max-width: 992px) {
  .aqwi-map {
    .map {
      height: 650px;

      iframe {
        height: 650px;
      }
    }

    .aqwi-map-info {
      width: 100%;
      font-size: 24px;
      line-height: 30px;
      padding: 1rem 4rem;

      li {
        margin-bottom: 0.5rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .aqwi-map {
    .map {
      height: 500px;

      iframe {
        height: 500px;
      }
    }

    .aqwi-map-info {
      font-size: 18px;
      line-height: 26px;
      padding: 1rem 2rem;

      li {
        margin-bottom: 0.25rem;

        .icon img {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .aqwi-map {
    .aqwi-map-info {
      font-size: 16px;
      line-height: 22px;
      padding: 0.5rem 1rem;

      li .icon img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
