.aqwi-objects {
  .aqwi-products-item {
    margin-bottom: 9rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .aqwi-objects-list {
    ol {
      padding-left: 2rem;
    }
  }

  .aqwi-objects-item-title {
    h2 {
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }

  .aqwi-objects-list-title {
    font-weight: 500;
  }

  .aqwi-objects-item-img {
    padding: 4rem;
    border: 5px solid $dark;
    border-radius: $radius10;

    img {
      max-width: 100%;
      max-height: 520px;
      height: auto;
    }
  }

  .aqwi-list-multilevel {
    & > ul, & > ol {
      padding-left: 5rem;
    }

    & > ul > li,
    & > ol > li {
      &::before {
        content: none;
      }

      & > ul {
        &::before {
          content: none;
        }

        li:first-child {
          margin-top: 1rem;
        }
      }
    }
  }
}

@media (max-width: 1650px) {
  .aqwi-objects {
    .aqwi-products-item {
      margin-bottom: 6rem;
    }

    .aqwi-objects-item-img {
      padding: 2rem;
    }

    .aqwi-list-multilevel {
      & > ul, & > ol {
        padding-left: 4rem;
      }
    }
  }
}

@media (max-width: 1400px) {
  .aqwi-objects {
    .aqwi-products-item {
      margin-bottom: 5rem;
    }

    .aqwi-objects-item-img {
      padding: 1.5rem;
    }

    .aqwi-list-multilevel {
      & > ul, & > ol {
        padding-left: 3rem;
      }
    }

  }
}

@media (max-width: 1200px) {
  .aqwi-objects {
    .aqwi-objects-item-img {
      padding: 1.25rem;
    }
  }
}

@media (max-width: 992px) {
  .aqwi-objects {
    .aqwi-products-item {
      margin-bottom: 4rem;
    }

    .aqwi-objects-item-img {
      padding: 1rem;
    }

    .aqwi-list-multilevel {
      & > ul > li,
      & > ol > li {
        & > ul {
          li:first-child {
            margin-top: 0.75rem;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .aqwi-objects {
    .aqwi-products-item {
      margin-bottom: 3rem;
    }

    .aqwi-objects-item-img {
      padding: 0.5rem;
    }

    .aqwi-list-multilevel {
      & > ul, & > ol {
        padding-left: 2.5rem;
      }

      & > ul > li,
      & > ol > li {
        & > ul {
          li:first-child {
            margin-top: 0.75rem;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .aqwi-objects {
    .aqwi-objects-item-img {
      padding: 0.25rem;
    }

    .aqwi-list-multilevel {
      & > ul, & > ol {
        padding-left: 2.5rem;
      }
    }
  }
}
