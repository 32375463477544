.name_page_wrapper .services_page {
  background-image: url("../img/background-services.jpg");
}

.aqwi-services {
  .aqwi-service-card-item {
    display: flex;
    color: $white;
    margin-bottom: 100px;
    padding: 5rem 3rem;
    border-radius: $radius10;
    background-color: $dark;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .aqwi-service-card-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin-right: 2rem;
  }

  .aqwi-service-card-title {
    font-size: 65px;
    font-weight: 600;
    line-height: 77px;
    text-transform: uppercase;
  }

  .aqwi-service-card-desc {
    font-size: 28px;
    line-height: 35px;
  }
}

@media (max-width: 1600px) {
  .aqwi-services {
    .aqwi-service-card-item {
      padding: 5rem 4rem;
    }

    .aqwi-service-card-title {
      font-size: 45px;
      line-height: 55px;
    }

    .aqwi-service-card-desc {
      font-size: 26px;
      line-height: 30px;
    }
  }
}

@media (max-width: 1400px) {
  .aqwi-services {
    .aqwi-service-card-item {
      padding: 4rem;
    }

    .aqwi-service-card-title {
      font-size: 36px;
      line-height: 45px;
    }

    .aqwi-service-card-desc {
      font-size: 24px;
      line-height: 28px;
    }

    .aqwi-service-card-image {
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 1200px) {
  .aqwi-services {
    .aqwi-service-card-item {
      margin-bottom: 50px;
      padding: 3rem;
    }

    .aqwi-service-card-title {
      font-size: 32px;
      line-height: 40px;
    }

    .aqwi-service-card-desc {
      font-size: 22px;
    }
  }
}

@media (max-width: 992px) {
  .aqwi-services {
    .aqwi-service-card-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 30px;
      margin-bottom: 30px;
      padding: 3rem 2rem;
    }

    .aqwi-service-card-image {
      & img {
        width: 510px;
        height: 300px;
      }
    }

    .aqwi-service-card-info,
    .aqwi-service-card-image {
      width: 100%;
      text-align: center;
      margin-right: 0;
    }

    .aqwi-service-card-title {
      font-size: 28px;
      line-height: 35px;
      margin-bottom: 0.5rem;
    }

    .aqwi-service-card-desc {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

@media (max-width: 768px) {
  .aqwi-services {
    .aqwi-service-card-item {
      padding: 1.5rem;
    }

    .aqwi-service-card-image {
      & img {
        width: 340px;
        height: 200px;
      }
    }

    .aqwi-service-card-title {
      font-size: 22px;
      line-height: 30px;
    }

    .aqwi-service-card-desc {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

@media (max-width: 576px) {
  .aqwi-services {
    .aqwi-service-card-item {
      padding: 2rem 1rem;
    }
  }

  .aqwi-service-card-title {
    font-size: 20px;
    line-height: 26px;
  }

  .aqwi-service-card-desc {
    font-size: 16px;
  }
}
