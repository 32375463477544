@font-face {
    font-family: 'Roboto';
    src: url("../font/Roboto-Bold.woff2") format("woff2"),
    url("../font/Roboto-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url("../font/Roboto-Medium.woff2") format("woff2"),
    url("../font/Roboto-Medium.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url("../font/Roboto-Regular.woff2") format("woff2"),
    url("../font/Roboto-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url("../font/Roboto-Light.woff2") format("woff2"),
    url("../font/Roboto-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url("../font/Jost-Regular.woff2") format("woff2"),
    url("../font/Jost-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url("../font/Jost-Medium.woff2") format("woff2"),
    url("../font/Jost-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}