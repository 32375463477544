.aqwi-accordion {
  .aqwi-accordion-logo-box {
    display: flex;
    align-items: center;
    color: $white;
    padding: 2rem 9rem;
    border-radius: $radius10;
    background-color: $dark;

    .aqwi-accordion-logo-img {
      display: flex;
      align-items: center;
      width: 178px;
      height: 182px;
      margin-right: 2rem;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .aqwi-accordion-logo-desc {
      span {
        display: block;
      }
    }
  }

  .accordion-header {
    .accordion-button {
      font-size: 56px;
      font-weight: 500;
      line-height: 66px;
      text-transform: uppercase;
    }
  }

  .accordion-body {
    padding: 0;

    .aqwi-accordion-lists {
      display: flex;
      flex-direction: column;
      row-gap: 4rem;
    }
  }

  .aqwi-accordion-title {
    font-weight: 500;
  }

  .aqwi-accordion-list {
    .list-chain {
      ul {
        padding-left: 4rem;

        &::before {
          left: 15px;
        }

        li {
          position: relative;

          &::before {
            position: absolute;
            top: 5px;
            left: -65px;
            display: inline-block;
            width: 40px;
            height: 40px;
            content: "";
          }
        }
      }
    }

    ul {
      position: relative;
      padding-left: 2rem;

      li {
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          display: inline-block;
          width: 10px;
          height: 10px;
          vertical-align: middle;
          margin-right: 1rem;
          border-radius: 50%;
          content: "";
          background-color: rgba(131, 11, 10, 1);
        }
      }

      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 9px;
        border-radius: $radius10;
        content: "";
        background-color: rgba(131, 11, 10, 1);
      }
    }
  }
}

.aqwi-accordion.aqwi-vacancies-accordion {
  .accordion-item {
    color: $white;
    margin-bottom: 3rem;
    border-radius: $radius10;
    background-color: $dark;
  }

  .accordion-button {
    color: $white;
    background-color: $dark;
  }

  ul {
    &::before {
      content: none;
    }

    li {
      &::before {
        background-color: $white;
      }
    }
  }

  .aqwi-vacancies-accordion-email {
    a {
      color: $white;
      margin-left: 1rem;
    }
  }
}

@media (max-width: 1650px) {
  .aqwi-accordion {
    .aqwi-accordion-list {
      .list-chain {
        ul {
          li {
            &::before {
              top: 3px;
              left: -60px;
              width: 30px;
              height: 30px;
            }
          }
        }
      }

      ul {
        &::before {
          width: 6px;
        }
      }
    }

    .aqwi-accordion-logo-box {
      padding: 2rem 6rem;
    }

    .accordion-header {
      .accordion-button {
        font-size: 42px;
        line-height: initial;

        &::after {
          width: 50px;
          height: 50px;
        }
      }
    }

    .accordion-body {
      .aqwi-accordion-lists {
        row-gap: 3rem;
      }
    }
  }
}

@media (max-width: 1400px) {
  .aqwi-accordion {
    .aqwi-accordion-logo-box {
      padding: 2rem 4rem;

      .aqwi-accordion-logo-img {
        width: 150px;
        height: 150px;
      }
    }

    .accordion-header {
      .accordion-button {
        font-size: 36px;
        padding: 40px 0;
      }
    }
  }
}

@media (max-width: 1200px) {
  .aqwi-accordion {
    .accordion-header {
      .accordion-button {
        font-size: 32px;

        &::after {
          width: 40px;
          height: 40px;
        }
      }
    }

    .aqwi-accordion-lists {
      ul {
        padding-left: 1.5rem;
      }
    }
  }
}

@media (max-width: 992px) {
  .aqwi-accordion {
    .aqwi-accordion-list {
      .list-chain {
        ul {
          padding-left: 3rem;

          li {
            &::before {
              top: 1px;
              left: -42px;
              width: 25px;
              height: 25px;
            }
          }
        }
      }

      ul {
        li {
          margin-bottom: 0.75rem;
        }
      }
    }

    .aqwi-accordion-logo-box {
      padding: 2rem;

      .aqwi-accordion-logo-img {
        width: 130px;
        height: 130px;
      }
    }

    .accordion-header {
      .accordion-button {
        font-size: 24px;
        padding: 20px 0;

        &::after {
          width: 30px;
          height: 30px;
        }
      }
    }

    .accordion-body {
      .aqwi-accordion-lists {
        row-gap: 2rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .aqwi-accordion {
    .aqwi-accordion-list {
      ul {
        li {
          margin-bottom: 0.5rem;
        }
      }
    }

    .aqwi-accordion-logo-box {
      padding: 1rem;
    }

    .accordion-header {
      .accordion-button {
        font-size: 18px;

        &::after {
          width: 25px;
          height: 25px;
        }
      }
    }

    .aqwi-accordion-lists {
      ul {
        padding-left: 1rem;
      }
    }
  }
}

@media (max-width: 576px) {
  .aqwi-accordion {
    .aqwi-accordion-list {
      .list-chain {
        ul {
          li {
            &::before {
              left: -41px;
              width: 20px;
              height: 20px;
            }
          }
        }
      }

      ul {
        &::before {
          width: 4px;
        }
      }
    }

    .aqwi-accordion-logo-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 1rem;
      text-align: center;

      .aqwi-accordion-logo-img {
        width: 100px;
        height: 100px;
        margin: 0;
      }
    }

    .accordion-header {
      .accordion-button {
        &::after {
          width: 20px;
          height: 20px;
        }
      }
    }

    .accordion-body {
      .aqwi-accordion-lists {
        row-gap: 1rem;
      }
    }
  }
}
