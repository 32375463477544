.name_page_wrapper {
  &.small {
    .name_page {
      min-height: 250px;
      height: auto;
      padding: 2rem 0;

      .aqwi-title-page {
        h1 {
          font-size: 65px;
          font-weight: 600;
          line-height: 77px;
        }
      }
    }
  }

  .name_page {
    display: flex;
    height: 1080px;
    min-height: 100vh;
    padding: 104px 0;
    background-color: rgba(131, 11, 10, 1);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .aqwi-title-page {
      display: flex;
      align-items: center;
      height: 100%;

      h1 {
        color: $white;
        font-family: Jost, serif;
        font-size: 138px;
        font-weight: 400;
        font-style: normal;
        line-height: 200px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }
    }
  }
}

@media (max-width: 1600px) {
  .name_page_wrapper {
    &.small {
      .name_page {
        min-height: 200px;

        .aqwi-title-page {
          h1 {
            font-size: 40px;
            line-height: 50px;
          }
        }
      }
    }

    .name_page {
      .aqwi-title-page {
        h1 {
          font-size: 70px;
          line-height: 120px;
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .name_page_wrapper {
    .name_page {
      height: 100vh;

      &.small {
        height: auto;
      }
    }
  }
}

@media (max-width: 992px) {
  .name_page_wrapper {
    &.small {
      .name_page {
        .aqwi-title-page {
          h1 {
            font-size: 30px;
            line-height: 40px;
          }
        }
      }
    }

    .name_page {
      .aqwi-title-page {
        h1 {
          font-size: 60px;
          line-height: 90px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .name_page_wrapper {
    &.small {
      .name_page {
        .aqwi-title-page {
          h1 {
            font-size: 26px;
            line-height: 33px;
          }
        }
      }
    }

    .name_page {
      .aqwi-title-page {
        h1 {
          font-size: 50px;
          line-height: 70px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .name_page_wrapper {
    &.small {
      .name_page {
        min-height: 130px;

        .aqwi-title-page {
          h1 {
            font-size: 22px;
            line-height: 28px;
          }
        }
      }
    }

    .name_page {
      .aqwi-title-page {
        h1 {
          font-size: 36px;
          line-height: 45px;
        }
      }
    }
  }
}
