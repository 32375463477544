$track-color: #d5d5d5;
$thumb-color: #333;
$scroll-width: 6px;

/* width */
::-webkit-scrollbar {
  width: $scroll-width;
}

/* Track */
::-webkit-scrollbar-track {
  background: $track-color;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $thumb-color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #444;
}

html,
body,
main {
  scrollbar-width: thin;
  scrollbar-color: $thumb-color $track-color;
}

@media (max-width: 768px) {
  html,
  body,
  main {
    scrollbar-width: none;
  }

  ::-webkit-scrollbar {
    width: 0;
  }
}
