body {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
  font-family: Roboto, serif;
  font-size: 40px;
  font-weight: normal;
  line-height: 50px;
}

main {
  flex-grow: 1;
}

footer {
  flex-shrink: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.title {
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
}

h3 {
  font-size: 28px;
}

.aqwi-pos-rel {
  position: relative;
}
.aqwi-pos-rel .aqwi-link-block {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  background-color: transparent;
}

.aqwi-content-pg {
  padding: 100px 0;
}

.aqwi-breadcrumb {
  padding-top: 100px;
}
.aqwi-breadcrumb a {
  display: inline-block;
  color: #A6A6A6;
  font-size: 50px;
  line-height: 60px;
  text-decoration: none;
}
.aqwi-breadcrumb a span::after {
  display: block;
  width: 0;
  height: 3px;
  margin-left: 0.25rem;
  content: "";
  background-color: #A6A6A6;
  transition: width 0.3s ease-in-out;
}
.aqwi-breadcrumb a:hover span::after, .aqwi-breadcrumb a:focus span::after {
  width: 100%;
}

.h2-size {
  font-size: 56px;
  line-height: 66px;
}

.pg-b-100 {
  padding-bottom: 100px;
}

.mg-b-1 {
  margin-bottom: 1rem;
}

.mg-b-3 {
  margin-bottom: 3rem;
}

.mg-b-5 {
  margin-bottom: 5rem;
}

.aqwi-section-title.light {
  color: #ffffff;
}
.aqwi-section-title.dark {
  color: #242424;
}
.aqwi-section-title h2 {
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
}

.aqwi-list ul, .aqwi-list ol {
  position: relative;
  padding-left: 2rem;
}
.aqwi-list ul li, .aqwi-list ol li {
  margin-bottom: 1rem;
}
.aqwi-list ul li:last-child, .aqwi-list ol li:last-child {
  margin-bottom: 0;
}
.aqwi-list ul li::before, .aqwi-list ol li::before {
  display: inline-block;
  width: 10px;
  height: 10px;
  vertical-align: middle;
  margin-right: 1rem;
  border-radius: 50%;
  content: "";
  background-color: rgb(131, 11, 10);
}
.aqwi-list ul::before, .aqwi-list ol::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 9px;
  border-radius: 10px;
  content: "";
  background-color: rgb(131, 11, 10);
}
.aqwi-list ul.line-type, .aqwi-list ol.line-type {
  padding-left: 3rem;
}
.aqwi-list ul.line-type li, .aqwi-list ol.line-type li {
  position: relative;
}
.aqwi-list ul.line-type li::before, .aqwi-list ol.line-type li::before {
  position: absolute;
  top: 5px;
  left: -64px;
  display: inline-block;
  width: 40px;
  height: 40px;
  content: "";
}

.hidden {
  display: none;
}

@media (min-width: 1650px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1596px;
  }
}
@media (max-width: 1650px) {
  body {
    font-size: 26px;
    line-height: 30px;
  }
  .h2-size {
    font-size: 40px;
    line-height: 50px;
  }
  .aqwi-breadcrumb {
    padding-top: 50px;
  }
  .aqwi-breadcrumb a {
    font-size: 40px;
    line-height: 50px;
  }
  .aqwi-content-pg {
    padding: 50px 0;
  }
  .aqwi-list ul, .aqwi-list ol {
    padding-left: 1.5rem;
  }
  .aqwi-list ul::before, .aqwi-list ol::before {
    width: 6px;
  }
}
@media (max-width: 1400px) {
  body {
    font-size: 24px;
    line-height: 28px;
  }
  .mg-b-3 {
    margin-bottom: 2rem;
  }
  .mg-b-5 {
    margin-bottom: 4rem;
  }
}
@media (max-width: 1200px) {
  body {
    font-size: 22px;
    line-height: 26px;
  }
  .h2-size {
    font-size: 36px;
    line-height: 45px;
  }
  .mg-b-1 {
    margin-bottom: 0.75rem;
  }
  .mg-b-5 {
    margin-bottom: 3rem;
  }
}
@media (max-width: 992px) {
  body {
    font-size: 20px;
    line-height: 24px;
  }
  .h2-size {
    font-size: 32px;
    line-height: 40px;
  }
  .aqwi-breadcrumb {
    padding-top: 40px;
  }
  .aqwi-breadcrumb a {
    font-size: 36px;
    line-height: 42px;
  }
  .aqwi-content-pg {
    padding: 40px 0;
  }
  .mg-b-3 {
    margin-bottom: 1rem;
  }
  .mg-b-1 {
    margin-bottom: 0.5rem;
  }
  .aqwi-list ul, .aqwi-list ol {
    padding-left: 1rem;
  }
  .aqwi-list ul li, .aqwi-list ol li {
    margin-bottom: 0.75rem;
  }
}
@media (max-width: 768px) {
  body {
    font-size: 18px;
  }
  .h2-size {
    font-size: 28px;
    line-height: 33px;
  }
  .aqwi-breadcrumb {
    padding-top: 30px;
  }
  .aqwi-breadcrumb a {
    font-size: 28px;
    line-height: 35px;
  }
  .aqwi-content-pg {
    padding: 30px 0;
  }
  .aqwi-list ul li, .aqwi-list ol li {
    margin-bottom: 0.5rem;
  }
  .mg-b-5 {
    margin-bottom: 2rem;
  }
}
@media (max-width: 576px) {
  body {
    font-size: 16px;
    line-height: 22px;
  }
  .h2-size {
    font-size: 22px;
    line-height: 26px;
  }
  .aqwi-breadcrumb {
    padding-top: 20px;
  }
  .aqwi-breadcrumb a {
    font-size: 24px;
    line-height: 30px;
  }
  .aqwi-content-pg {
    padding: 20px 0;
  }
  .mg-b-1 {
    margin-bottom: 0.25rem;
  }
  .aqwi-list ul, .aqwi-list ol {
    padding-left: 0.5rem;
  }
  .aqwi-list ul::before, .aqwi-list ol::before {
    width: 4px;
  }
}
header .menu__btn {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 82px;
  right: 148px;
  width: 81px;
  height: 81px;
  background-color: #242424;
  cursor: pointer;
  z-index: 1046;
  border-radius: 50%;
}
header .menu__btn > span,
header .menu__btn > span::before,
header .menu__btn > span::after {
  display: block;
  position: absolute;
  width: 50%;
  height: 3px;
  background-color: #ffffff;
  border-radius: 3px;
  transition-duration: 0.25s;
}
header .menu__btn > span:hover,
header .menu__btn > span::before:hover,
header .menu__btn > span::after:hover {
  background-color: #242424;
}
header .menu__btn > span::before {
  width: 100%;
  content: "";
  top: -8px;
}
header .menu__btn > span::after {
  width: 100%;
  content: "";
  top: 8px;
}
header #menu__toggle {
  display: none;
}
header #menu__toggle:checked ~ .menu__btn {
  transition-duration: 0.4s;
  background-color: transparent;
}
header #menu__toggle:checked ~ .menu__btn > span {
  transform: rotate(45deg);
}
header #menu__toggle:checked ~ .menu__btn > span::before {
  top: 0;
  transform: rotate(0);
}
header #menu__toggle:checked ~ .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}

@media (max-width: 1400px) {
  header .menu__btn {
    top: 50px;
    right: 80px;
  }
}
@media (max-width: 1024px) {
  header .menu__btn {
    top: 45px;
    right: 45px;
    width: 70px;
    height: 70px;
  }
}
@media (max-width: 768px) {
  header .menu__btn {
    width: 60px;
    height: 60px;
  }
}
@media (max-width: 576px) {
  header .menu__btn {
    top: 30px;
    right: 30px;
    width: 60px;
    height: 60px;
  }
}
@font-face {
  font-family: "Roboto";
  src: url("../font/Roboto-Bold.woff2") format("woff2"), url("../font/Roboto-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../font/Roboto-Medium.woff2") format("woff2"), url("../font/Roboto-Medium.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../font/Roboto-Regular.woff2") format("woff2"), url("../font/Roboto-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../font/Roboto-Light.woff2") format("woff2"), url("../font/Roboto-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Jost";
  src: url("../font/Jost-Regular.woff2") format("woff2"), url("../font/Jost-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../font/Jost-Medium.woff2") format("woff2"), url("../font/Jost-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
.main-page main {
  min-height: 100vh;
}
.main-page .aqwi-main-title h1 {
  color: #ffffff;
  font-family: Jost, serif;
  font-size: 200px;
  font-weight: 500;
  line-height: 110px;
  letter-spacing: 3.5rem;
  margin-bottom: 2rem;
}
.main-page .aqwi-main-title h1 span {
  display: block;
  font-size: 60px;
  font-weight: 400;
  letter-spacing: 1.5rem;
}
.main-page .aqwi-main {
  min-height: 100vh;
  padding: 104px 0;
  background-color: #242424;
  background-image: url("../img/aqwi-main.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}
.main-page .aqwi-main .aqwi-main-logo {
  margin-bottom: 330px;
}
.main-page .aqwi-main .aqwi-main-logo img {
  max-width: 100%;
  height: auto;
}
.main-page .aqwi-main-desc {
  width: 670px;
  color: #ffffff;
  font-size: 27px;
  line-height: 33px;
  font-weight: 300;
}
.main-page .aqwi-main-desc p {
  margin: 0;
}
.main-page .aqwi-home-section {
  padding: 100px 0;
}
.main-page .aqwi-home-section.light {
  background-color: #ffffff;
}
.main-page .aqwi-home-section.dark {
  background-color: #242424;
}
.main-page .aqwi-home-section .aqwi-section-title {
  width: 50%;
}
.main-page .aqwi-home-section .aqwi-section-title.light {
  color: #ffffff;
}
.main-page .aqwi-home-section .aqwi-section-title.dark {
  color: #242424;
}
.main-page .aqwi-home-section .aqwi-section-title h2 {
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
}
.main-page .aqwi-key-directions {
  background-color: #242424;
}
.main-page .aqwi-key-directions .aqwi-key-directions-cards {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 100px;
}
.main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  width: 40%;
  color: #ffffff;
  padding: 110px 2rem 130px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #242424;
  box-shadow: 4px 4px 81px rgba(255, 255, 255, 0.3);
  transition: 0.25s;
}
.main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card.active {
  color: #242424;
  background-color: #ffffff;
}
.main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card:nth-child(1) {
  left: 0;
  z-index: 4;
}
.main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card:nth-child(2) {
  z-index: 3;
  margin-left: -20%;
}
.main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card:nth-child(3) {
  z-index: 2;
  margin-left: -20%;
}
.main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card:nth-child(4) {
  z-index: 1;
  margin-left: -20%;
}
.main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-number {
  position: absolute;
  top: 30px;
  left: 30px;
  font-size: 70px;
}
.main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-image {
  text-align: center;
}
.main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-image img {
  width: 100%;
  height: 100%;
}
.main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-title {
  font-size: 35px;
  font-weight: 600;
  line-height: 41px;
  text-transform: uppercase;
  margin-bottom: 1.25rem;
}
.main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-desc {
  font-size: 27px;
  line-height: 32px;
  font-weight: 400;
}
.main-page .aqwi-key-directions .aqwi-group-buttons .btn-big {
  font-size: 40px;
  line-height: initial;
  padding: 2rem 5rem;
}
.main-page .aqwi-objects .aqwi-object-tick {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.main-page .aqwi-objects .aqwi-object-items {
  height: 650px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.main-page .aqwi-objects .aqwi-object-items::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.main-page .aqwi-objects .aqwi-object-item {
  display: flex;
  color: #ffffff;
  margin-bottom: 100px;
  padding: 6rem 3rem;
  border-radius: 10px;
  background-color: #242424;
}
.main-page .aqwi-objects .aqwi-object-item:last-child {
  margin-bottom: 0;
}
.main-page .aqwi-objects .aqwi-object-item-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  padding-right: 1.5rem;
}
.main-page .aqwi-objects .aqwi-object-item-img {
  width: 50%;
  border-radius: 10px;
  overflow: hidden;
}
.main-page .aqwi-objects .aqwi-object-item-img img {
  width: 100%;
  height: 100%;
}
.main-page .aqwi-objects .aqwi-object-title {
  font-weight: 600;
  font-size: 46px;
  line-height: 55px;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
.main-page .aqwi-objects .aqwi-object-desc {
  font-size: 28px;
  line-height: 33px;
}
.main-page .aqwi-objects .aqwi-object-item-link {
  position: relative;
  height: 648px;
  background-image: url("../img/object_more.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-clip: border-box;
  background-size: cover;
}
.main-page .aqwi-objects .aqwi-object-item-link a {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  background-color: transparent;
}
.main-page .aqwi-customers .aqwi-customers-slider img {
  max-width: 300px;
  height: auto;
}
.main-page .aqwi-customers .aqwi-customers-slider-wrap-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 1rem;
}
.main-page .aqwi-gratitude .aqwi-gratitude-slider {
  margin: 0 -20px;
}
.main-page .aqwi-gratitude .aqwi-gratitude-slider .slick-slide {
  padding: 0 20px;
}
.main-page .aqwi-gratitude .aqwi-gratitude-slider-item {
  display: flex;
  align-items: start;
  color: #ffffff;
  padding: 6rem 3rem;
  border-radius: 10px;
  background-color: #242424;
}
.main-page .aqwi-gratitude .aqwi-gratitude-slider-wrap-img-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 242px;
  height: 242px;
  margin-bottom: 1.25rem;
  border-radius: 50%;
  background-color: #ffffff;
}
.main-page .aqwi-gratitude .aqwi-gratitude-slider-wrap-img-icon img {
  max-width: 100%;
  height: auto;
}
.main-page .aqwi-gratitude .aqwi-gratitude-slider-wrap-img {
  margin-right: 4rem;
}
.main-page .aqwi-gratitude .aqwi-gratitude-slider-img {
  display: flex;
  justify-content: center;
  text-align: center;
}
.main-page .aqwi-gratitude .aqwi-gratitude-slider-img img {
  max-width: 100%;
  height: auto;
}
.main-page .aqwi-gratitude .aqwi-gratitude-slider-item-title {
  font-weight: 600;
  font-size: 46px;
  line-height: 55px;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
.main-page .aqwi-gratitude .aqwi-gratitude-slider-item-desc {
  font-size: 28px;
  line-height: 33px;
}

@media (max-width: 1600px) {
  .main-page .aqwi-main-title h1 {
    font-size: 100px;
    line-height: 70px;
    letter-spacing: 2.5rem;
    margin-bottom: 2rem;
  }
  .main-page .aqwi-main-title h1 span {
    display: block;
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 1.2rem;
  }
  .main-page .aqwi-main-desc {
    width: 500px;
    font-size: 26px;
  }
  .main-page .aqwi-home-section {
    padding: 50px 0;
  }
  .main-page .aqwi-home-section .aqwi-section-title {
    width: 100%;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards {
    margin-bottom: 75px;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card {
    padding: 100px 2rem;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-number {
    font-size: 60px;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-title {
    font-size: 28px;
    line-height: 30px;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-desc {
    font-size: 26px;
    line-height: 30px;
  }
  .main-page .aqwi-key-directions .aqwi-group-buttons .btn-big {
    font-size: 36px;
    padding: 1rem 4rem;
  }
  .main-page .aqwi-objects .aqwi-object-title {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 1rem;
  }
  .main-page .aqwi-objects .aqwi-object-desc {
    font-size: 26px;
    line-height: 30px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-item-title {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 1rem;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-item-desc {
    font-size: 26px;
    line-height: 30px;
  }
}
@media (max-width: 1400px) {
  .main-page .aqwi-main-title h1 {
    margin-bottom: 1.5rem;
  }
  .main-page .aqwi-main-desc {
    font-size: 24px;
    line-height: 28px;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards {
    margin-bottom: 50px;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card {
    padding: 100px 2rem 25px 2rem;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-number {
    font-size: 50px;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-desc {
    font-size: 24px;
    line-height: 26px;
  }
  .main-page .aqwi-key-directions .aqwi-group-buttons .btn-big {
    font-size: 32px;
  }
  .main-page .aqwi-objects .aqwi-object-item-link {
    height: 550px;
  }
  .main-page .aqwi-objects .aqwi-object-title {
    font-size: 36px;
    line-height: 45px;
  }
  .main-page .aqwi-objects .aqwi-object-desc {
    font-size: 24px;
    line-height: 26px;
  }
  .main-page .aqwi-objects .aqwi-object-item-img {
    height: 300px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-wrap-img-icon {
    margin-bottom: 1rem;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-wrap-img-icon {
    width: 210px;
    height: 210px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-img img {
    width: 140px;
    height: 170px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-item-title {
    font-size: 36px;
    line-height: 45px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-item-desc {
    font-size: 24px;
    line-height: 26px;
  }
}
@media (max-width: 1200px) {
  .main-page .aqwi-main-title h1 {
    margin-bottom: 1.25rem;
  }
  .main-page .aqwi-main-desc {
    font-size: 22px;
    line-height: 26px;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card {
    padding: 60px 1.5rem 1rem 1.5rem;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-number {
    top: 15px;
    left: 15px;
    font-size: 45px;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-title {
    font-size: 26px;
    line-height: 30px;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-desc {
    font-size: 22px;
    line-height: 26px;
  }
  .main-page .aqwi-key-directions .aqwi-group-buttons .btn-big {
    font-size: 28px;
    padding: 1rem 3rem;
  }
  .main-page .aqwi-objects .aqwi-object-tick {
    margin-top: 1rem;
  }
  .main-page .aqwi-objects .aqwi-object-tick img {
    width: 40px;
    height: 25px;
  }
  .main-page .aqwi-objects .aqwi-object-item {
    margin-bottom: 50px;
    padding: 4rem 3rem;
  }
  .main-page .aqwi-objects .aqwi-object-item-link {
    height: 450px;
  }
  .main-page .aqwi-objects .aqwi-object-title {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 1rem;
  }
  .main-page .aqwi-objects .aqwi-object-desc {
    font-size: 22px;
    line-height: 26px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-item {
    min-height: 462px;
    padding: 2rem;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-wrap-img-icon {
    width: 180px;
    height: 180px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-wrap-img-icon img {
    width: 110px;
    height: 110px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-img img {
    width: 120px;
    height: 150px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-item-title {
    font-size: 32px;
    line-height: 40px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-item-desc {
    font-size: 22px;
    line-height: 26px;
  }
}
@media (max-width: 996px) {
  .main-page .aqwi-main-title h1 {
    margin-bottom: 1rem;
  }
  .main-page .aqwi-main-desc {
    width: 450px;
    font-size: 20px;
    line-height: 26px;
  }
  .main-page .aqwi-home-section {
    padding: 30px 0;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards {
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 30px;
    margin-bottom: 30px;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card {
    width: 100%;
    padding: 50px 2rem 2rem;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card:nth-child(1), .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card:nth-child(2), .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card:nth-child(3), .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card:nth-child(4) {
    z-index: 1;
    margin-left: 0;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-number {
    top: 25px;
    left: 25px;
    font-size: 36px;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-image {
    margin-bottom: 1rem;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-image img {
    width: 60%;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-title {
    margin-bottom: 1rem;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-desc {
    font-size: 20px;
    line-height: 26px;
  }
  .main-page .aqwi-key-directions .aqwi-group-buttons .btn-big {
    font-size: 26px;
    padding: 1rem 2rem;
  }
  .main-page .aqwi-objects .aqwi-object-item {
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    margin-bottom: 30px;
    padding: 3rem 2rem;
  }
  .main-page .aqwi-objects .aqwi-object-item-link {
    height: 350px;
  }
  .main-page .aqwi-objects .aqwi-object-item-text {
    width: 100%;
  }
  .main-page .aqwi-objects .aqwi-object-item-img {
    width: 80%;
    height: 80%;
  }
  .main-page .aqwi-objects .aqwi-object-title {
    font-size: 28px;
    line-height: 35px;
  }
  .main-page .aqwi-objects .aqwi-object-desc {
    font-size: 20px;
    line-height: 26px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
    min-height: 715px;
    padding: 2rem 2rem 1rem;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-wrap-img {
    margin: 0;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-wrap-img-icon {
    width: 150px;
    height: 150px;
    margin-bottom: 0.75rem;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-wrap-img-icon img {
    width: 100px;
    height: 100px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-img img {
    width: 110px;
    height: 135px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-item-title {
    font-size: 28px;
    line-height: 35px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-item-desc {
    font-size: 20px;
    line-height: 26px;
  }
}
@media (max-width: 768px) {
  .main-page .aqwi-home-section {
    padding: 20px 0;
  }
  .main-page .aqwi-main-title h1 {
    font-size: 90px;
    line-height: 65px;
    letter-spacing: 2rem;
  }
  .main-page .aqwi-main-title h1 span {
    font-size: 32px;
    letter-spacing: 1rem;
  }
  .main-page .aqwi-main-desc {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards {
    margin-bottom: 40px;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card {
    padding: 50px 1.5rem 1.5rem;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-number {
    font-size: 32px;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-image img {
    width: 70%;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-title {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 0.5rem;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-desc {
    font-size: 18px;
    line-height: 22px;
  }
  .main-page .aqwi-key-directions .aqwi-group-buttons .btn-big {
    font-size: 24px;
    padding: 0.75rem 2rem;
  }
  .main-page .aqwi-objects .aqwi-object-tick img {
    width: 30px;
    height: 20px;
  }
  .main-page .aqwi-objects .aqwi-object-item {
    padding: 1.5rem;
  }
  .main-page .aqwi-objects .aqwi-object-item-link {
    height: 250px;
  }
  .main-page .aqwi-objects .aqwi-object-title {
    font-size: 22px;
    line-height: 30px;
  }
  .main-page .aqwi-objects .aqwi-object-desc {
    font-size: 18px;
    line-height: 26px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-item {
    min-height: 690px;
    padding: 1.5rem 1rem 0.5rem;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-item-title {
    font-size: 22px;
    line-height: 30px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-item-desc {
    font-size: 18px;
    line-height: 26px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-wrap-img-icon {
    width: 140px;
    height: 140px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-wrap-img-icon img {
    width: 80px;
    height: 80px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-img img {
    width: 110px;
    height: 125px;
  }
}
@media (max-width: 576px) {
  .main-page .aqwi-main-title h1 {
    font-size: 60px;
    line-height: 50px;
    letter-spacing: 1.5rem;
    margin-bottom: 0.75rem;
  }
  .main-page .aqwi-main-title h1 span {
    font-size: 22px;
    letter-spacing: 0.75rem;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-number {
    font-size: 28px;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-image img {
    width: 90%;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-title {
    font-size: 20px;
    line-height: 24px;
  }
  .main-page .aqwi-key-directions .aqwi-key-directions-cards .aqwi-key-directions-card .aqwi-key-directions-card-desc {
    font-size: 16px;
    line-height: 22px;
  }
  .main-page .aqwi-key-directions .aqwi-group-buttons .btn-big {
    font-size: 22px;
    padding: 0.5rem 1.5rem;
  }
  .main-page .aqwi-objects .aqwi-object-item-link {
    height: 200px;
  }
  .main-page .aqwi-objects .aqwi-object-title {
    font-size: 20px;
    line-height: 26px;
  }
  .main-page .aqwi-objects .aqwi-object-desc {
    font-size: 16px;
    line-height: 22px;
  }
  .main-page .aqwi-objects .aqwi-object-item-img {
    width: 100%;
    height: 100%;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-item {
    min-height: 100%;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-wrap-img-icon {
    width: 130px;
    height: 130px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-img img {
    width: 100px;
    height: 115px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-item-title {
    font-size: 20px;
    line-height: 26px;
  }
  .main-page .aqwi-gratitude .aqwi-gratitude-slider-item-desc {
    font-size: 16px;
    line-height: 22px;
  }
  .main-page .aqwi-customers .aqwi-customers-slider img {
    max-width: 280px;
  }
  .main-page .aqwi-customers .aqwi-customers-slider-wrap-img {
    justify-content: center;
  }
}
.name_page_wrapper.small .name_page {
  min-height: 250px;
  height: auto;
  padding: 2rem 0;
}
.name_page_wrapper.small .name_page .aqwi-title-page h1 {
  font-size: 65px;
  font-weight: 600;
  line-height: 77px;
}
.name_page_wrapper .name_page {
  display: flex;
  height: 1080px;
  min-height: 100vh;
  padding: 104px 0;
  background-color: rgb(131, 11, 10);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.name_page_wrapper .name_page .aqwi-title-page {
  display: flex;
  align-items: center;
  height: 100%;
}
.name_page_wrapper .name_page .aqwi-title-page h1 {
  color: #ffffff;
  font-family: Jost, serif;
  font-size: 138px;
  font-weight: 400;
  font-style: normal;
  line-height: 200px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media (max-width: 1600px) {
  .name_page_wrapper.small .name_page {
    min-height: 200px;
  }
  .name_page_wrapper.small .name_page .aqwi-title-page h1 {
    font-size: 40px;
    line-height: 50px;
  }
  .name_page_wrapper .name_page .aqwi-title-page h1 {
    font-size: 70px;
    line-height: 120px;
  }
}
@media (max-width: 1400px) {
  .name_page_wrapper .name_page {
    height: 100vh;
  }
  .name_page_wrapper .name_page.small {
    height: auto;
  }
}
@media (max-width: 992px) {
  .name_page_wrapper.small .name_page .aqwi-title-page h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .name_page_wrapper .name_page .aqwi-title-page h1 {
    font-size: 60px;
    line-height: 90px;
  }
}
@media (max-width: 768px) {
  .name_page_wrapper.small .name_page .aqwi-title-page h1 {
    font-size: 26px;
    line-height: 33px;
  }
  .name_page_wrapper .name_page .aqwi-title-page h1 {
    font-size: 50px;
    line-height: 70px;
  }
}
@media (max-width: 576px) {
  .name_page_wrapper.small .name_page {
    min-height: 130px;
  }
  .name_page_wrapper.small .name_page .aqwi-title-page h1 {
    font-size: 22px;
    line-height: 28px;
  }
  .name_page_wrapper .name_page .aqwi-title-page h1 {
    font-size: 36px;
    line-height: 45px;
  }
}
.name_page_wrapper .services_detail {
  background-image: url("../img/background-services.jpg");
}

.detail-it-desc p {
  margin: 0;
}

.aqwi-cards .aqwi-servive-detail-title h2 {
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
}
.aqwi-cards .aqwi-service-detail-image img {
  width: 100%;
  max-width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
}
.aqwi-cards .aqwi-service-detail-content .accordion-body {
  padding-top: 0;
}
.aqwi-cards .aqwi-service-detail-content .detail-group-buttons {
  margin-top: 9rem;
}
.aqwi-cards .aqwi-service-detail-content .aqwi-accordion-desc {
  margin-bottom: 2.5rem;
}
.aqwi-cards .aqwi-service-detail-content .aqwi-accordion-desc p {
  margin: 0;
}

@media (max-width: 1650px) {
  .aqwi-cards .aqwi-service-detail-content .detail-group-buttons {
    margin-top: 6rem;
  }
}
@media (max-width: 1400px) {
  .aqwi-cards .aqwi-service-detail-content .detail-group-buttons {
    margin-top: 4rem;
  }
  .aqwi-cards .aqwi-service-detail-content .aqwi-accordion-desc {
    margin-bottom: 2rem;
  }
}
@media (max-width: 1200px) {
  .aqwi-cards .aqwi-service-detail-content .detail-group-buttons {
    margin-top: 3rem;
  }
}
@media (max-width: 768px) {
  .aqwi-cards .aqwi-service-detail-content .detail-group-buttons {
    margin-top: 2rem;
  }
  .aqwi-cards .aqwi-service-detail-content .aqwi-accordion-desc {
    margin-bottom: 1rem;
  }
}
.name_page_wrapper .services_page {
  background-image: url("../img/background-services.jpg");
}

.aqwi-services .aqwi-service-card-item {
  display: flex;
  color: #ffffff;
  margin-bottom: 100px;
  padding: 5rem 3rem;
  border-radius: 10px;
  background-color: #242424;
}
.aqwi-services .aqwi-service-card-item:last-child {
  margin-bottom: 0;
}
.aqwi-services .aqwi-service-card-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  margin-right: 2rem;
}
.aqwi-services .aqwi-service-card-title {
  font-size: 65px;
  font-weight: 600;
  line-height: 77px;
  text-transform: uppercase;
}
.aqwi-services .aqwi-service-card-desc {
  font-size: 28px;
  line-height: 35px;
}

@media (max-width: 1600px) {
  .aqwi-services .aqwi-service-card-item {
    padding: 5rem 4rem;
  }
  .aqwi-services .aqwi-service-card-title {
    font-size: 45px;
    line-height: 55px;
  }
  .aqwi-services .aqwi-service-card-desc {
    font-size: 26px;
    line-height: 30px;
  }
}
@media (max-width: 1400px) {
  .aqwi-services .aqwi-service-card-item {
    padding: 4rem;
  }
  .aqwi-services .aqwi-service-card-title {
    font-size: 36px;
    line-height: 45px;
  }
  .aqwi-services .aqwi-service-card-desc {
    font-size: 24px;
    line-height: 28px;
  }
  .aqwi-services .aqwi-service-card-image img {
    max-width: 100%;
    height: auto;
  }
}
@media (max-width: 1200px) {
  .aqwi-services .aqwi-service-card-item {
    margin-bottom: 50px;
    padding: 3rem;
  }
  .aqwi-services .aqwi-service-card-title {
    font-size: 32px;
    line-height: 40px;
  }
  .aqwi-services .aqwi-service-card-desc {
    font-size: 22px;
  }
}
@media (max-width: 992px) {
  .aqwi-services .aqwi-service-card-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
    margin-bottom: 30px;
    padding: 3rem 2rem;
  }
  .aqwi-services .aqwi-service-card-image img {
    width: 510px;
    height: 300px;
  }
  .aqwi-services .aqwi-service-card-info,
  .aqwi-services .aqwi-service-card-image {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }
  .aqwi-services .aqwi-service-card-title {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 0.5rem;
  }
  .aqwi-services .aqwi-service-card-desc {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (max-width: 768px) {
  .aqwi-services .aqwi-service-card-item {
    padding: 1.5rem;
  }
  .aqwi-services .aqwi-service-card-image img {
    width: 340px;
    height: 200px;
  }
  .aqwi-services .aqwi-service-card-title {
    font-size: 22px;
    line-height: 30px;
  }
  .aqwi-services .aqwi-service-card-desc {
    font-size: 18px;
    line-height: 24px;
  }
}
@media (max-width: 576px) {
  .aqwi-services .aqwi-service-card-item {
    padding: 2rem 1rem;
  }
  .aqwi-service-card-title {
    font-size: 20px;
    line-height: 26px;
  }
  .aqwi-service-card-desc {
    font-size: 16px;
  }
}
.aqwi-news-detail .aqwi-news-detail-title h2 {
  font-weight: 500;
  text-transform: uppercase;
}
.aqwi-news-detail .aqwi-news-detail-img {
  max-width: 100%;
  height: 776px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.aqwi-news-detail .aqwi-news-detail-desc {
  font-weight: 400;
}
.aqwi-news-detail .aqwi-news-detail-date {
  color: #A6A6A6;
  font-size: 25px;
  line-height: 30px;
}

.aqwi-cards-news-wrapper .news-item {
  margin-bottom: 3rem;
}
.aqwi-cards-news-wrapper .news-item:last-child {
  margin-bottom: 0;
}
.aqwi-cards-news-wrapper .aqwi-card-news-item-content {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 457px;
  position: relative;
  display: flex;
  align-items: end;
  padding: 2rem;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1rem;
}
.aqwi-cards-news-wrapper .aqwi-card-news-desc {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #860b10;
  color: #ffffff;
  z-index: 1;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 20px;
  font-weight: 500;
  padding: 2rem 1rem;
}
.aqwi-cards-news-wrapper .aqwi-card-news-link a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 2;
}
.aqwi-cards-news-wrapper .aqwi-card-news-item-date {
  color: #A6A6A6;
  font-size: 25px;
  line-height: 30px;
}
.aqwi-cards-news-wrapper .aqwi-group-buttons {
  margin-top: 3rem;
}

.name_page_wrapper .company_page {
  background-image: url("../img/background-company.jpg");
}

.aqwi-company .aqwi-company-title {
  margin-bottom: 3rem;
}
.aqwi-company .aqwi-company-title h2 {
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0;
}
.aqwi-company .aqwi-company-section {
  margin-bottom: 100px;
}
.aqwi-company .aqwi-lists-wrapper .aqwi-list-title {
  font-weight: 500;
  margin-bottom: 2rem;
}
.aqwi-company .aqwi-lists-wrapper .aqwi-list-item ul {
  position: relative;
  padding-left: 2rem;
}
.aqwi-company .aqwi-lists-wrapper .aqwi-list-item ul li {
  margin-bottom: 1rem;
}
.aqwi-company .aqwi-lists-wrapper .aqwi-list-item ul li:last-child {
  margin-bottom: 0;
}
.aqwi-company .aqwi-lists-wrapper .aqwi-list-item ul li::before {
  display: inline-block;
  width: 10px;
  height: 10px;
  vertical-align: middle;
  margin-right: 1rem;
  border-radius: 50%;
  content: "";
  background-color: rgb(131, 11, 10);
}
.aqwi-company .aqwi-lists-wrapper .aqwi-list-item ul::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 9px;
  border-radius: 10px;
  content: "";
  background-color: rgb(131, 11, 10);
}
.aqwi-company .aqwi-company-img {
  display: flex;
  align-items: end;
  height: 776px;
  padding: 4em 0;
  background-image: url("../img/company_page.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.aqwi-company .aqwi-company-img .aqwi-company-img-text {
  color: #ffffff;
}
.aqwi-company .aqwi-company-img .aqwi-company-img-text .title {
  text-transform: uppercase;
  margin-bottom: 2rem;
}
.aqwi-company .aqwi-company-img .aqwi-company-img-text span {
  display: block;
}
.aqwi-company .aqwi-company-utp ul {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.aqwi-company .aqwi-company-utp ul li {
  position: relative;
}
.aqwi-company .aqwi-company-utp ul li .list-content {
  display: flex;
  align-items: center;
  column-gap: 5rem;
}
.aqwi-company .aqwi-company-utp ul li span {
  display: block;
}
.aqwi-company .aqwi-company-utp ul li .list-icon {
  width: 75px;
  height: 75px;
}
.aqwi-company .aqwi-company-utp ul li .list-icon img {
  max-width: 100%;
  height: auto;
}
.aqwi-company .aqwi-company-utp ul li .list-title {
  font-weight: 500;
}
.aqwi-company .aqwi-company-team ul li {
  display: flex;
  align-items: start;
  color: #ffffff;
  margin-bottom: 3rem;
  padding: 4rem;
  border-radius: 10px;
  background-color: #242424;
}
.aqwi-company .aqwi-company-team ul li:last-child {
  margin-bottom: 0;
}
.aqwi-company .aqwi-company-team .person-img {
  margin-right: 3rem;
  border-radius: 10px;
  overflow: hidden;
}
.aqwi-company .aqwi-company-team .person-img img {
  max-width: 100%;
  height: auto;
}
.aqwi-company .aqwi-company-team .person-content {
  width: 100%;
  overflow: auto;
}
.aqwi-company .aqwi-company-team .person-name {
  font-size: 44px;
  font-weight: 600;
  line-height: 55px;
}
.aqwi-company .aqwi-company-team .person-post {
  font-weight: 400;
}
.aqwi-company .aqwi-company-team .person-info {
  font-weight: 400;
  margin-top: 4rem;
}
.aqwi-company .aqwi-company-team .person-info p {
  margin-bottom: 0.25rem;
}
.aqwi-company .aqwi-group-buttons {
  margin-top: 3rem;
}
.aqwi-company .aqwi-company-vacancies .accordion-item .accordion-header button {
  padding-right: 3rem;
  padding-left: 3rem;
}
.aqwi-company .aqwi-company-vacancies .accordion-item .accordion-body {
  font-size: 50px;
  line-height: 60px;
  padding: 0 3rem 3rem;
}
.aqwi-company .aqwi-company-vacancies .accordion-item .accordion-body .aqwi-accordion-lists {
  margin-bottom: 3rem;
}
.aqwi-company .aqwi-company-vacancies .accordion-item .accordion-body .aqwi-accordion-lists .aqwi-accordion-title {
  margin-bottom: 0.5rem;
}
.aqwi-company .aqwi-company-vacancies .accordion-item .accordion-body .aqwi-accordion-lists .aqwi-accordion-list ul {
  padding-left: 0;
}
.aqwi-company .aqwi-company-vacancies .accordion-item .accordion-body .aqwi-accordion-lists .aqwi-accordion-list ul li {
  margin-bottom: 0.25rem;
}
.aqwi-company .aqwi-company-vacancies .accordion-item .accordion-body .aqwi-vacancies-accordion-email {
  margin-top: 4rem;
}

@media (max-width: 1650px) {
  .aqwi-company .aqwi-company-title {
    margin-bottom: 2rem;
  }
  .aqwi-company .aqwi-lists-wrapper .aqwi-list-item ul::before {
    width: 6px;
  }
  .aqwi-company .aqwi-company-img {
    height: 675px;
  }
  .aqwi-company .aqwi-company-img .aqwi-company-img-text .title {
    margin-bottom: 1rem;
  }
  .aqwi-company .aqwi-company-utp ul li .list-content {
    column-gap: 3rem;
  }
  .aqwi-company .aqwi-company-utp ul li .list-icon {
    width: 60px;
    height: 60px;
  }
  .aqwi-company .aqwi-company-utp ul li .list-icon img {
    width: 60px;
    height: 60px;
  }
  .aqwi-company .aqwi-company-team .person-img {
    width: 600px;
  }
  .aqwi-company .aqwi-company-team .person-name {
    font-size: 32px;
    line-height: 40px;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-header button::after {
    width: 60px;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-body {
    font-size: 36px;
    line-height: 42px;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-body .aqwi-accordion-lists {
    margin-bottom: 2rem;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-body .aqwi-vacancies-accordion-email {
    margin-top: 3rem;
  }
}
@media (max-width: 1200px) {
  .aqwi-company .aqwi-lists-wrapper .aqwi-list-title {
    margin-bottom: 0.75rem;
  }
  .aqwi-company .aqwi-lists-wrapper .aqwi-list-item ul {
    padding-left: 1.5rem;
  }
  .aqwi-company .aqwi-lists-wrapper .aqwi-list-item ul li {
    margin-bottom: 0.75rem;
  }
  .aqwi-company .aqwi-company-utp ul {
    row-gap: 1.5rem;
  }
  .aqwi-company .aqwi-company-utp ul li .list-content {
    column-gap: 2rem;
  }
  .aqwi-company .aqwi-company-utp ul li .list-icon {
    width: 50px;
    height: 50px;
  }
  .aqwi-company .aqwi-company-utp ul li .list-icon img {
    width: 50px;
    max-width: none;
    height: 50px;
  }
  .aqwi-company .aqwi-company-team ul li {
    padding: 3rem;
  }
  .aqwi-company .aqwi-company-team .person-img {
    width: auto;
  }
  .aqwi-company .aqwi-company-team .person-name {
    font-size: 26px;
    line-height: 33px;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-header button::after {
    width: 45px;
    height: 30px;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-body {
    font-size: 32px;
    line-height: 36px;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-body .aqwi-accordion-lists {
    margin-bottom: 1.75rem;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-body .aqwi-vacancies-accordion-email {
    margin-top: 2.75rem;
  }
}
@media (max-width: 992px) {
  .aqwi-company .aqwi-lists-wrapper .aqwi-list-title {
    margin-bottom: 0.5rem;
  }
  .aqwi-company .aqwi-company-team ul li {
    padding: 2rem;
  }
  .aqwi-company .aqwi-company-team .person-name {
    font-size: 22px;
    line-height: 26px;
  }
  .aqwi-company .aqwi-company-section {
    margin-bottom: 50px;
  }
  .aqwi-company .aqwi-lists-wrapper .aqwi-list-item ul li {
    margin-bottom: 0.5rem;
  }
  .aqwi-company .aqwi-company-img {
    height: 550px;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-header button {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-header button::after {
    width: 40px;
    height: 25px;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-body {
    font-size: 20px;
    line-height: 24px;
    padding: 0 2rem 2rem;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-body .aqwi-accordion-lists {
    margin-bottom: 1rem;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-body .aqwi-accordion-lists .aqwi-accordion-title {
    margin-bottom: 0.25rem;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-body .aqwi-vacancies-accordion-email {
    margin-top: 1.5rem;
  }
}
@media (max-width: 768px) {
  .aqwi-company .aqwi-company-title {
    margin-bottom: 1rem;
  }
  .aqwi-company .aqwi-lists-wrapper .aqwi-list-item ul {
    padding-left: 1rem;
  }
  .aqwi-company .aqwi-lists-wrapper .aqwi-list-item ul li {
    margin-bottom: 0.5rem;
  }
  .aqwi-company .aqwi-company-img {
    height: 450px;
  }
  .aqwi-company .aqwi-company-img .aqwi-company-img-text .title {
    margin-bottom: 0.5rem;
  }
  .aqwi-company .aqwi-company-utp ul {
    row-gap: 1rem;
  }
  .aqwi-company .aqwi-company-utp ul li .list-content {
    column-gap: 2rem;
  }
  .aqwi-company .aqwi-company-utp ul li .list-icon {
    width: 40px;
    height: 40px;
  }
  .aqwi-company .aqwi-company-utp ul li .list-icon img {
    width: 40px;
    height: 40px;
  }
  .aqwi-company .aqwi-company-team ul li {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 25px;
    text-align: center;
    margin-bottom: 2rem;
    padding: 2rem 1.5rem;
  }
  .aqwi-company .aqwi-company-team ul li .person-img {
    margin: 0;
  }
  .aqwi-company .aqwi-company-team ul li .person-img img {
    width: 350px;
  }
  .aqwi-company .aqwi-company-team ul li .person-name {
    font-size: 22px;
    line-height: 26px;
  }
  .aqwi-company .aqwi-company-team ul li .person-info {
    margin-top: 1rem;
  }
  .aqwi-company .aqwi-group-buttons {
    margin-top: 2rem;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-header button {
    font-size: 20px;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-header button::after {
    width: 35px;
    height: 20px;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-body {
    font-size: 18px;
    line-height: 26px;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-body .aqwi-accordion-lists .aqwi-accordion-title {
    margin-bottom: 0;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-body .aqwi-accordion-lists .aqwi-accordion-list ul li {
    margin-bottom: 0;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-body .aqwi-accordion-lists .aqwi-accordion-list ul li::before {
    margin-right: 0.5rem;
  }
}
@media (max-width: 576px) {
  .aqwi-company .aqwi-lists-wrapper .aqwi-list-item ul::before {
    width: 4px;
  }
  .aqwi-company .aqwi-company-section {
    margin-bottom: 35px;
  }
  .aqwi-company .aqwi-company-team ul li {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  .aqwi-company .aqwi-company-team ul li .person-img img {
    width: 250px;
  }
  .aqwi-company .aqwi-company-team ul li .person-name {
    font-size: 20px;
  }
  .aqwi-company .aqwi-company-team ul li .person-info {
    margin-top: 0.75rem;
  }
  .aqwi-company .aqwi-group-buttons {
    margin-top: 1.5rem;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-header button {
    font-size: 18px;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-header button::after {
    width: 30px;
    height: 16px;
  }
  .aqwi-company .aqwi-company-vacancies .accordion-item .accordion-body {
    font-size: 16px;
    line-height: 24px;
    padding: 0 1.5rem 1.5rem;
  }
}
.aqwi-objects .aqwi-products-item {
  margin-bottom: 9rem;
}
.aqwi-objects .aqwi-products-item:last-child {
  margin-bottom: 0;
}
.aqwi-objects .aqwi-objects-list ol {
  padding-left: 2rem;
}
.aqwi-objects .aqwi-objects-item-title h2 {
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0;
}
.aqwi-objects .aqwi-objects-list-title {
  font-weight: 500;
}
.aqwi-objects .aqwi-objects-item-img {
  padding: 4rem;
  border: 5px solid #242424;
  border-radius: 10px;
}
.aqwi-objects .aqwi-objects-item-img img {
  max-width: 100%;
  max-height: 520px;
  height: auto;
}
.aqwi-objects .aqwi-list-multilevel > ul, .aqwi-objects .aqwi-list-multilevel > ol {
  padding-left: 5rem;
}
.aqwi-objects .aqwi-list-multilevel > ul > li::before, .aqwi-objects .aqwi-list-multilevel > ol > li::before {
  content: none;
}
.aqwi-objects .aqwi-list-multilevel > ul > li > ul::before, .aqwi-objects .aqwi-list-multilevel > ol > li > ul::before {
  content: none;
}
.aqwi-objects .aqwi-list-multilevel > ul > li > ul li:first-child, .aqwi-objects .aqwi-list-multilevel > ol > li > ul li:first-child {
  margin-top: 1rem;
}

@media (max-width: 1650px) {
  .aqwi-objects .aqwi-products-item {
    margin-bottom: 6rem;
  }
  .aqwi-objects .aqwi-objects-item-img {
    padding: 2rem;
  }
  .aqwi-objects .aqwi-list-multilevel > ul, .aqwi-objects .aqwi-list-multilevel > ol {
    padding-left: 4rem;
  }
}
@media (max-width: 1400px) {
  .aqwi-objects .aqwi-products-item {
    margin-bottom: 5rem;
  }
  .aqwi-objects .aqwi-objects-item-img {
    padding: 1.5rem;
  }
  .aqwi-objects .aqwi-list-multilevel > ul, .aqwi-objects .aqwi-list-multilevel > ol {
    padding-left: 3rem;
  }
}
@media (max-width: 1200px) {
  .aqwi-objects .aqwi-objects-item-img {
    padding: 1.25rem;
  }
}
@media (max-width: 992px) {
  .aqwi-objects .aqwi-products-item {
    margin-bottom: 4rem;
  }
  .aqwi-objects .aqwi-objects-item-img {
    padding: 1rem;
  }
  .aqwi-objects .aqwi-list-multilevel > ul > li > ul li:first-child, .aqwi-objects .aqwi-list-multilevel > ol > li > ul li:first-child {
    margin-top: 0.75rem;
  }
}
@media (max-width: 768px) {
  .aqwi-objects .aqwi-products-item {
    margin-bottom: 3rem;
  }
  .aqwi-objects .aqwi-objects-item-img {
    padding: 0.5rem;
  }
  .aqwi-objects .aqwi-list-multilevel > ul, .aqwi-objects .aqwi-list-multilevel > ol {
    padding-left: 2.5rem;
  }
  .aqwi-objects .aqwi-list-multilevel > ul > li > ul li:first-child, .aqwi-objects .aqwi-list-multilevel > ol > li > ul li:first-child {
    margin-top: 0.75rem;
  }
}
@media (max-width: 576px) {
  .aqwi-objects .aqwi-objects-item-img {
    padding: 0.25rem;
  }
  .aqwi-objects .aqwi-list-multilevel > ul, .aqwi-objects .aqwi-list-multilevel > ol {
    padding-left: 2.5rem;
  }
}
.name_page_wrapper .contacts_page {
  background-image: url("../img/background-contacts.jpg");
}

.aqwi-map {
  position: relative;
}
.aqwi-map .map {
  width: 100%;
  height: 800px;
}
.aqwi-map .map iframe {
  height: 800px;
}
.aqwi-map .map .mapTitle {
  position: absolute;
  z-index: 1000;
  width: 270px;
  font-size: 14px;
  line-height: initial;
  padding: 5px;
  border: 1px solid black;
  border-radius: 10px;
  background: white;
}
.aqwi-map .aqwi-map-info {
  position: absolute;
  right: 50%;
  bottom: 0;
  left: 50%;
  width: 1100px;
  padding: 3rem;
  background-color: #ffffff;
  transform: translate(-50%, 0);
}
.aqwi-map .aqwi-map-info li {
  margin-bottom: 1rem;
}
.aqwi-map .aqwi-map-info li.address {
  display: flex;
}
.aqwi-map .aqwi-map-info li:last-child {
  margin-bottom: 0;
}
.aqwi-map .aqwi-map-info li .icon {
  margin-right: 1rem;
}
.aqwi-map .aqwi-map-info li .icon img {
  width: 50px;
  height: 50px;
}
.aqwi-map .aqwi-map-info a {
  color: #242424;
}

@media (max-width: 1600px) {
  .aqwi-map .aqwi-map-info {
    width: 750px;
    font-size: 26px;
    line-height: 30px;
    padding: 2rem 3rem;
  }
  .aqwi-map .aqwi-map-info li {
    margin-bottom: 0.75rem;
  }
  .aqwi-map .aqwi-map-info li .icon img {
    width: 35px;
    height: 35px;
  }
}
@media (max-width: 1200px) {
  .aqwi-map .aqwi-map-info {
    width: 950px;
  }
}
@media (max-width: 992px) {
  .aqwi-map .map {
    height: 650px;
  }
  .aqwi-map .map iframe {
    height: 650px;
  }
  .aqwi-map .aqwi-map-info {
    width: 100%;
    font-size: 24px;
    line-height: 30px;
    padding: 1rem 4rem;
  }
  .aqwi-map .aqwi-map-info li {
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 768px) {
  .aqwi-map .map {
    height: 500px;
  }
  .aqwi-map .map iframe {
    height: 500px;
  }
  .aqwi-map .aqwi-map-info {
    font-size: 18px;
    line-height: 26px;
    padding: 1rem 2rem;
  }
  .aqwi-map .aqwi-map-info li {
    margin-bottom: 0.25rem;
  }
  .aqwi-map .aqwi-map-info li .icon img {
    width: 25px;
    height: 25px;
  }
}
@media (max-width: 576px) {
  .aqwi-map .aqwi-map-info {
    font-size: 16px;
    line-height: 22px;
    padding: 0.5rem 1rem;
  }
  .aqwi-map .aqwi-map-info li .icon img {
    width: 20px;
    height: 20px;
  }
}
.name_page_wrapper .documents_page {
  background-image: url("../img/background-documents.jpg");
}

.aqwi-documents .slick-dots {
  margin: 15px 0 5px 0;
}
.aqwi-documents-slider .slick-slide {
  margin: 0 70px;
}
.aqwi-documents-slider-img {
  width: 100%;
  height: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.aqwi-documents-slider-img a.glightbox {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.aqwi-documents-slider-img a.glightbox img {
  max-width: 100%;
  height: 100%;
  object-fit: fill;
  object-position: center;
}
.aqwi-documents-slider-img a.document-download {
  text-align: center;
  color: black;
  line-height: initial;
  margin-top: 10px;
  padding-bottom: 5px;
  transition: all 0.3s;
}
.aqwi-documents-slider-img a.document-download:hover {
  opacity: 0.8;
}

@media (max-width: 1650px) {
  .aqwi-documents-slider .slick-slide {
    margin: 0 40px;
  }
  .aqwi-documents-slider-img {
    width: 380px;
    height: 590px;
  }
  .aqwi-documents .pg-b-100 {
    padding-bottom: 75px;
  }
}
@media (max-width: 1400px) {
  .aqwi-documents-slider .slick-slide {
    margin: 0 30px;
  }
  .aqwi-documents-slider-img {
    width: 334px;
    height: 540px;
  }
  .aqwi-documents .pg-b-100 {
    padding-bottom: 50px;
  }
}
@media (max-width: 1200px) {
  .aqwi-documents-slider .slick-slide {
    margin: 0 25px;
  }
  .aqwi-documents-slider-img {
    width: 280px;
    height: 485px;
  }
  .aqwi-documents .pg-b-100 {
    padding-bottom: 50px;
  }
}
@media (max-width: 992px) {
  .aqwi-documents-slider-img {
    width: 323px;
    height: 530px;
  }
}
@media (max-width: 768px) {
  .aqwi-documents .slick-dots {
    margin: 30px 0 5px 0;
  }
  .aqwi-documents-slider .slick-slide {
    margin: 0 23px;
  }
  .aqwi-documents-slider-img {
    width: 235px;
    height: 400px;
  }
  .aqwi-documents .pg-b-100 {
    padding-bottom: 25px;
  }
}
@media (max-width: 576px) {
  .aqwi-documents-slider-img {
    width: 300px;
    height: 465px;
    margin: 0 auto;
  }
  .aqwi-documents .pg-b-100 {
    padding-bottom: 20px;
  }
}
.aqwi-accordion .aqwi-accordion-logo-box {
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: 2rem 9rem;
  border-radius: 10px;
  background-color: #242424;
}
.aqwi-accordion .aqwi-accordion-logo-box .aqwi-accordion-logo-img {
  display: flex;
  align-items: center;
  width: 178px;
  height: 182px;
  margin-right: 2rem;
}
.aqwi-accordion .aqwi-accordion-logo-box .aqwi-accordion-logo-img img {
  max-width: 100%;
  height: auto;
}
.aqwi-accordion .aqwi-accordion-logo-box .aqwi-accordion-logo-desc span {
  display: block;
}
.aqwi-accordion .accordion-header .accordion-button {
  font-size: 56px;
  font-weight: 500;
  line-height: 66px;
  text-transform: uppercase;
}
.aqwi-accordion .accordion-body {
  padding: 0;
}
.aqwi-accordion .accordion-body .aqwi-accordion-lists {
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
}
.aqwi-accordion .aqwi-accordion-title {
  font-weight: 500;
}
.aqwi-accordion .aqwi-accordion-list .list-chain ul {
  padding-left: 4rem;
}
.aqwi-accordion .aqwi-accordion-list .list-chain ul::before {
  left: 15px;
}
.aqwi-accordion .aqwi-accordion-list .list-chain ul li {
  position: relative;
}
.aqwi-accordion .aqwi-accordion-list .list-chain ul li::before {
  position: absolute;
  top: 5px;
  left: -65px;
  display: inline-block;
  width: 40px;
  height: 40px;
  content: "";
}
.aqwi-accordion .aqwi-accordion-list ul {
  position: relative;
  padding-left: 2rem;
}
.aqwi-accordion .aqwi-accordion-list ul li {
  margin-bottom: 1rem;
}
.aqwi-accordion .aqwi-accordion-list ul li:last-child {
  margin-bottom: 0;
}
.aqwi-accordion .aqwi-accordion-list ul li::before {
  display: inline-block;
  width: 10px;
  height: 10px;
  vertical-align: middle;
  margin-right: 1rem;
  border-radius: 50%;
  content: "";
  background-color: rgb(131, 11, 10);
}
.aqwi-accordion .aqwi-accordion-list ul::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 9px;
  border-radius: 10px;
  content: "";
  background-color: rgb(131, 11, 10);
}

.aqwi-accordion.aqwi-vacancies-accordion .accordion-item {
  color: #ffffff;
  margin-bottom: 3rem;
  border-radius: 10px;
  background-color: #242424;
}
.aqwi-accordion.aqwi-vacancies-accordion .accordion-button {
  color: #ffffff;
  background-color: #242424;
}
.aqwi-accordion.aqwi-vacancies-accordion ul::before {
  content: none;
}
.aqwi-accordion.aqwi-vacancies-accordion ul li::before {
  background-color: #ffffff;
}
.aqwi-accordion.aqwi-vacancies-accordion .aqwi-vacancies-accordion-email a {
  color: #ffffff;
  margin-left: 1rem;
}

@media (max-width: 1650px) {
  .aqwi-accordion .aqwi-accordion-list .list-chain ul li::before {
    top: 3px;
    left: -60px;
    width: 30px;
    height: 30px;
  }
  .aqwi-accordion .aqwi-accordion-list ul::before {
    width: 6px;
  }
  .aqwi-accordion .aqwi-accordion-logo-box {
    padding: 2rem 6rem;
  }
  .aqwi-accordion .accordion-header .accordion-button {
    font-size: 42px;
    line-height: initial;
  }
  .aqwi-accordion .accordion-header .accordion-button::after {
    width: 50px;
    height: 50px;
  }
  .aqwi-accordion .accordion-body .aqwi-accordion-lists {
    row-gap: 3rem;
  }
}
@media (max-width: 1400px) {
  .aqwi-accordion .aqwi-accordion-logo-box {
    padding: 2rem 4rem;
  }
  .aqwi-accordion .aqwi-accordion-logo-box .aqwi-accordion-logo-img {
    width: 150px;
    height: 150px;
  }
  .aqwi-accordion .accordion-header .accordion-button {
    font-size: 36px;
    padding: 40px 0;
  }
}
@media (max-width: 1200px) {
  .aqwi-accordion .accordion-header .accordion-button {
    font-size: 32px;
  }
  .aqwi-accordion .accordion-header .accordion-button::after {
    width: 40px;
    height: 40px;
  }
  .aqwi-accordion .aqwi-accordion-lists ul {
    padding-left: 1.5rem;
  }
}
@media (max-width: 992px) {
  .aqwi-accordion .aqwi-accordion-list .list-chain ul {
    padding-left: 3rem;
  }
  .aqwi-accordion .aqwi-accordion-list .list-chain ul li::before {
    top: 1px;
    left: -42px;
    width: 25px;
    height: 25px;
  }
  .aqwi-accordion .aqwi-accordion-list ul li {
    margin-bottom: 0.75rem;
  }
  .aqwi-accordion .aqwi-accordion-logo-box {
    padding: 2rem;
  }
  .aqwi-accordion .aqwi-accordion-logo-box .aqwi-accordion-logo-img {
    width: 130px;
    height: 130px;
  }
  .aqwi-accordion .accordion-header .accordion-button {
    font-size: 24px;
    padding: 20px 0;
  }
  .aqwi-accordion .accordion-header .accordion-button::after {
    width: 30px;
    height: 30px;
  }
  .aqwi-accordion .accordion-body .aqwi-accordion-lists {
    row-gap: 2rem;
  }
}
@media (max-width: 768px) {
  .aqwi-accordion .aqwi-accordion-list ul li {
    margin-bottom: 0.5rem;
  }
  .aqwi-accordion .aqwi-accordion-logo-box {
    padding: 1rem;
  }
  .aqwi-accordion .accordion-header .accordion-button {
    font-size: 18px;
  }
  .aqwi-accordion .accordion-header .accordion-button::after {
    width: 25px;
    height: 25px;
  }
  .aqwi-accordion .aqwi-accordion-lists ul {
    padding-left: 1rem;
  }
}
@media (max-width: 576px) {
  .aqwi-accordion .aqwi-accordion-list .list-chain ul li::before {
    left: -41px;
    width: 20px;
    height: 20px;
  }
  .aqwi-accordion .aqwi-accordion-list ul::before {
    width: 4px;
  }
  .aqwi-accordion .aqwi-accordion-logo-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    text-align: center;
  }
  .aqwi-accordion .aqwi-accordion-logo-box .aqwi-accordion-logo-img {
    width: 100px;
    height: 100px;
    margin: 0;
  }
  .aqwi-accordion .accordion-header .accordion-button::after {
    width: 20px;
    height: 20px;
  }
  .aqwi-accordion .accordion-body .aqwi-accordion-lists {
    row-gap: 1rem;
  }
}
.aqwi-btn {
  transition: 0.4s;
}
.aqwi-btn.btn-transparent {
  background-color: transparent;
  border: 2px solid #ffffff;
  border-radius: 10px;
  color: #ffffff;
}
.aqwi-btn.btn-transparent:hover {
  background-color: #ffffff;
  color: #242424;
}
.aqwi-btn.btn-big {
  border: 0;
  border-radius: 11px;
}
.aqwi-btn.btn-dark {
  background-color: #242424;
}
.aqwi-btn.btn-dark:hover {
  opacity: 0.9;
}
.aqwi-btn.btn-dark:active {
  opacity: 0.8;
}
.aqwi-btn.btn-dark.btn-big {
  font-size: 34px;
  line-height: 40px;
  padding: 2rem 2rem;
}
.aqwi-btn.btn-white {
  background-color: #ffffff;
}
.aqwi-btn.btn-white:hover {
  opacity: 0.8;
}
.aqwi-btn.btn-white:active {
  opacity: 0.6;
}
.aqwi-btn.btn-white.btn-big {
  font-size: 43px;
  line-height: 50px;
  padding: 2rem 5rem;
}

.modal-btn {
  border: none;
  border-radius: 11px;
  color: #fff;
  font-size: 18px;
  padding: 10px 16px;
  transition: all 0.2s linear;
}
.modal-btn:active {
  opacity: 0.8;
}
.modal-btn.btn-success {
  background: #830b0a;
}
.modal-btn.btn-success:hover {
  background: #a90403;
}
.modal-btn.btn-cancel {
  background: #ccc;
}
.modal-btn.btn-cancel:hover {
  background: #a2a2a2;
}

@media (max-width: 1650px) {
  .aqwi-btn.btn-dark.btn-big {
    padding: 1.5rem;
  }
}
@media (max-width: 1400px) {
  .aqwi-btn.btn-dark.btn-big {
    font-size: 32px;
    padding: 1.25rem;
  }
}
@media (max-width: 1200px) {
  .aqwi-btn.btn-dark.btn-big {
    font-size: 26px;
    padding: 1.25rem;
  }
}
@media (max-width: 992px) {
  .aqwi-btn.btn-dark.btn-big {
    font-size: 22px;
    padding: 1rem 1.25rem;
  }
}
@media (max-width: 768px) {
  .aqwi-btn.btn-dark.btn-big {
    font-size: 20px;
    padding: 0.5rem 1rem;
  }
}
@media (max-width: 576px) {
  .aqwi-btn.btn-dark.btn-big {
    font-size: 18px;
    padding: 0.5rem 0.75rem;
  }
}
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d5d5d5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #444;
}

html,
body,
main {
  scrollbar-width: thin;
  scrollbar-color: #333 #d5d5d5;
}

@media (max-width: 768px) {
  html,
  body,
  main {
    scrollbar-width: none;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
}
.offcanvas.offcanvas-end {
  width: 460px;
}

.slick-dots {
  position: static;
  margin: 5px 0;
}
.slick-dots li.slick-active button::before {
  opacity: 0.5;
}
.slick-dots li button::before {
  font-size: 70px;
  transition: all 0.3s;
  color: #999;
}

.accordion-item {
  border: 0;
}

.accordion-button {
  padding: 50px 0;
}

.accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: inherit;
  border: 0;
  background-color: transparent;
  box-shadow: none;
}

.accordion-button::after {
  width: 82px;
  height: 82px;
  background-image: url("../img/accordion_icon.svg");
  background-size: contain;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../img/accordion_icon.svg");
  transform: rotate(45deg);
}

.accordion-button.arrow::after {
  width: 67px;
  height: 40px;
  background-image: url("../img/arrow.svg");
  background-size: contain;
}

.accordion-button.arrow:not(.collapsed)::after {
  background-image: url("../img/arrow.svg");
  transform: rotate(180deg);
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 10px;
}

.modal-dialog {
  max-width: 750px;
}
.modal-header, .modal-body, .modal-footer {
  padding: 0;
}
.modal-header .btn-close {
  font-size: 22px;
  transition: all 0.3s linear;
}

#modalFeedback .modal-content {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  line-height: initial;
  padding: 25px;
}
#modalFeedback .modal-header, #modalFeedback .modal-body, #modalFeedback .modal-footer {
  padding: 0;
}
#modalFeedback .modal-header, #modalFeedback .modal-footer {
  border-top: none;
  border-bottom: none;
}
#modalFeedback .modal-header .modal-title {
  font-size: 24px;
}
#modalFeedback .modal-body {
  display: flex;
  flex-direction: column;
  align-self: center;
  row-gap: 25px;
  width: 100%;
  max-width: 570px;
}
#modalFeedback .modal-body textarea {
  scrollbar-width: none;
}
#modalFeedback .modal-body textarea::-webkit-scrollbar {
  width: 1rem;
}
#modalFeedback .modal-body textarea::-webkit-scrollbar-track {
  border: 1px solid #ced4da;
  border-top-width: 0;
  border-right-width: 0;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}
#modalFeedback .modal-body textarea::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 11px;
}
#modalFeedback .modal-body .form-control {
  font-size: 18px;
  padding: 12px 16px;
  border-radius: 11px;
}
#modalFeedback .modal-footer {
  display: flex;
  column-gap: 15px;
}
#modalFeedback .modal-footer .modal-btn {
  margin: 0;
}
#modalFeedback .modal-footer .btn-success {
  width: 140px;
}
#modalFeedback .modal-footer .btn-cancel {
  width: 110px;
}
#modalFeedback .modal-error {
  display: none;
  color: #dc3545;
  font-size: 12px;
  margin-top: 5px;
}
#modalFeedback .modal-error.visible {
  display: block;
}

#modalNotify .modal-content {
  padding: 25px;
  font-size: 25px;
}
#modalNotify .modal-header, #modalNotify .modal-body {
  padding: 0;
}
#modalNotify .modal-header {
  margin-bottom: 30px;
  border: none;
}

@media (max-width: 1400px) {
  .modal-dialog {
    max-width: 685px;
  }
  .modal-header .btn-close {
    font-size: 20px;
  }
  #modalFeedback .modal-body {
    max-width: 500px;
  }
  #modalNotify .modal-content {
    padding: 25px 20px;
    font-size: 23px;
  }
  #modalNotify .modal-header {
    margin-bottom: 25px;
  }
}
@media (max-width: 1200px) {
  .modal-dialog {
    max-width: 600px;
  }
  .modal-header .btn-close {
    font-size: 18px;
  }
  #modalFeedback .modal-header .modal-title {
    font-size: 22px;
  }
  #modalFeedback .modal-body {
    max-width: 445px;
  }
  #modalFeedback .modal-body .form-control {
    font-size: 16px;
    padding: 10px 14px;
  }
  #modalFeedback .modal-footer .btn-success {
    width: 120px;
  }
  #modalFeedback .modal-footer .btn-cancel {
    width: 90px;
  }
  #modalNotify .modal-content {
    font-size: 20px;
  }
  #modalNotify .modal-header {
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .modal-dialog {
    max-width: 500px;
  }
  .modal-header .btn-close {
    font-size: 16px;
  }
  #modalFeedback .modal-content {
    row-gap: 25px;
  }
  #modalFeedback .modal-header .modal-title {
    font-size: 20px;
  }
  #modalFeedback .modal-body {
    row-gap: 20px;
    max-width: 100%;
  }
  #modalFeedback .modal-footer .modal-btn {
    font-size: 16px;
  }
  #modalNotify .modal-content p {
    text-align: center;
    line-height: 30px;
  }
  #modalNotify .modal-header {
    margin-bottom: 15px;
  }
}
@media (max-width: 576px) {
  .modal-dialog {
    max-width: 100%;
  }
  .modal-header .btn-close {
    font-size: 16px;
  }
  #modalFeedback .modal-body {
    row-gap: 15px;
  }
  #modalFeedback .modal-body .form-control {
    padding: 8px 12px;
  }
}
.sidebar {
  padding-top: 200px;
  background-color: rgba(36, 36, 36, 0.9);
}
.sidebar .offcanvas-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 2rem 1rem 2rem;
}
.sidebar-list {
  row-gap: 32px;
  font-size: 27px;
  margin-bottom: 3rem;
}
.sidebar .nav-item {
  display: inline-flex;
  line-height: initial;
}
.sidebar .nav-link {
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0;
}
.sidebar .nav-link:hover, .sidebar .nav-link:target, .sidebar .nav-link:focus {
  color: #ffffff;
}
.sidebar .nav-link span::after {
  display: block;
  width: 0;
  height: 2px;
  margin-left: 0.25rem;
  content: "";
  background-color: #ffffff;
  transition: width 0.3s ease-in-out;
}
.sidebar .nav-link:hover span::after, .sidebar .nav-link:focus span::after {
  width: 100%;
}
.sidebar .sidebar-info-list {
  row-gap: 5px;
  font-size: 22px;
  line-height: initial;
}
.sidebar .sidebar-info-callback {
  margin-bottom: 1.5rem;
}
.sidebar .sidebar-info-content a {
  color: #A6A6A6;
  text-decoration: none;
}
.sidebar .sidebar-info button {
  font-size: 26px;
}

@media (max-width: 1600px) {
  .offcanvas.offcanvas-end {
    width: 400px;
  }
}
@media (max-width: 1400px) {
  .offcanvas.offcanvas-end {
    width: 350px;
  }
}
@media (max-width: 1024px) {
  .offcanvas.offcanvas-end {
    width: 280px;
  }
  .sidebar-list {
    row-gap: 25px;
    font-size: 22px;
    margin-bottom: 2rem;
  }
  .sidebar .sidebar-info-list {
    row-gap: 5px;
    font-size: 20px;
    line-height: initial;
  }
  .sidebar .sidebar-info button {
    font-size: 20px;
  }
}
@media (max-width: 1024px) and (max-height: 800px) {
  .offcanvas.offcanvas-end {
    width: 280px;
    padding-top: 125px;
  }
  .sidebar-list {
    row-gap: 22px;
  }
}
@media (max-width: 768px) {
  .offcanvas.offcanvas-end {
    width: 240px;
    padding-top: 125px;
  }
  .sidebar-list {
    row-gap: 20px;
    font-size: 20px;
    margin-bottom: 1.5rem;
  }
  .sidebar .sidebar-info-list {
    font-size: 16px;
  }
  .sidebar .sidebar-info button {
    font-size: 18px;
  }
}
@media (max-width: 768px) and (max-height: 480px) {
  .offcanvas.offcanvas-end {
    padding-top: 115px;
  }
  .sidebar-list {
    row-gap: 16px;
    font-size: 18px;
  }
  .sidebar .sidebar-info button {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .offcanvas.offcanvas-end {
    width: 100%;
    border: none;
  }
  .sidebar-list {
    align-items: center;
    row-gap: 16px;
    font-size: 18px;
    margin-bottom: 2rem;
  }
  .sidebar .sidebar-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .sidebar .sidebar-info-list {
    text-align: center;
  }
  .sidebar .sidebar-info button {
    font-size: 16px;
  }
}
footer {
  color: #ffffff;
  line-height: 30px;
  padding: 120px 0;
  background-color: #242424;
}
footer .footer_block {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #A6A6A6;
  font-size: 22px;
}
footer .footer_block a {
  color: #A6A6A6;
  text-decoration: none;
}
footer .footer_title {
  margin-bottom: 50px;
}
footer .footer_contacts__logo {
  margin-top: auto;
}
footer .footer_contacts__logo img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 1400px) {
  footer .footer_title {
    font-size: 26px;
  }
  footer .footer_contacts__content {
    font-size: 20px;
  }
}
@media (max-width: 1200px) {
  footer {
    line-height: 28px;
    padding: 90px 0;
  }
  footer .footer_title {
    font-size: 24px;
    margin-bottom: 30px;
  }
}
@media (max-width: 992px) {
  footer {
    line-height: 26px;
    padding: 60px 0;
  }
  footer .footer_title {
    font-size: 20px;
    margin-bottom: 25px;
  }
  footer .footer_contacts__content {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  footer {
    line-height: 24px;
    padding: 40px 0;
  }
  footer .footer_row {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 50px;
  }
  footer .footer_row .col-4, footer .footer_row .col-8 {
    width: 100%;
    text-align: center;
  }
  footer .footer_title {
    font-size: 18px;
    margin-bottom: 15px;
  }
  footer .footer_contacts__content {
    font-size: 16px;
  }
  footer .footer_contacts__logo {
    margin-top: 30px;
  }
}
@media (max-width: 576px) {
  footer {
    padding: 30px 0;
  }
  footer .footer_row {
    row-gap: 30px;
  }
  footer .footer_contacts__logo {
    margin-top: 10px;
  }
}