.sidebar {
  padding-top: 200px;
  background-color: rgba($dark, 0.9);

  .offcanvas-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 2rem 1rem 2rem;
  }

  &-list {
    row-gap: 32px;
    font-size: 27px;
    margin-bottom: 3rem;
  }

  .nav {
    &-item {
      display: inline-flex;
      line-height: initial;
    }

    &-link {
      color: $white;
      text-decoration: none;
      text-transform: uppercase;
      padding: 0;

      &:hover, &:target, &:focus {
        color: $white;
      }

      span::after {
        display: block;
        width: 0;
        height: 2px;
        margin-left: 0.25rem;
        content: "";
        background-color: $white;
        transition: width .3s ease-in-out;
      }

      &:hover span::after,
      &:focus span::after {
        width: 100%;
      }
    }
  }

  .sidebar-info {
    &-list {
      row-gap: 5px;
      font-size: 22px;
      line-height: initial;
    }

    &-callback {
      margin-bottom: 1.5rem;
    }

    &-content {
      a {
        color: $text_grey;
        text-decoration: none;
      }
    }

    button {
      font-size: 26px;
    }
  }
}

@media (max-width: 1600px) {
  .offcanvas {
    &.offcanvas-end {
      width: 400px;
    }
  }
}

@media (max-width: 1400px) {
  .offcanvas {
    &.offcanvas-end {
      width: 350px;
    }
  }
}

@media (max-width: 1024px) {
  .offcanvas {
    &.offcanvas-end {
      width: 280px;
    }
  }

  .sidebar {
    &-list {
      row-gap: 25px;
      font-size: 22px;
      margin-bottom: 2rem;
    }

    .sidebar-info {
      &-list {
        row-gap: 5px;
        font-size: 20px;
        line-height: initial;
      }

      //&-callback {
      //  margin-bottom: 1.25rem;
      //}

      button {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 1024px) and (max-height: 800px) {
  .offcanvas {
    &.offcanvas-end {
      width: 280px;
      padding-top: 125px;
    }
  }

  .sidebar {
    &-list {
      row-gap: 22px;
    }
  }
}

@media (max-width: 768px) {
  .offcanvas {
    &.offcanvas-end {
      width: 240px;
      padding-top: 125px;
    }
  }

  .sidebar {
    &-list {
      row-gap: 20px;
      font-size: 20px;
      margin-bottom: 1.5rem;
    }

    .sidebar-info {
      &-list {
        font-size: 16px;
      }

      button {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 768px) and (max-height: 480px) {
  .offcanvas {
    &.offcanvas-end {
      padding-top: 115px;
    }
  }

  .sidebar {
    &-list {
      row-gap: 16px;
      font-size: 18px;
    }

    .sidebar-info {
      button {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 576px) {
  .offcanvas {
    &.offcanvas-end {
      width: 100%;
      border: none;
    }
  }

  .sidebar {
    &-list {
      align-items: center;
      row-gap: 16px;
      font-size: 18px;
      margin-bottom: 2rem;
    }

    .sidebar-info {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-list {
        text-align: center;
      }

      button {
        font-size: 16px;
      }
    }
  }
}
