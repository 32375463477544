.aqwi-news-detail {
  .aqwi-news-detail-title {
    h2 {
      font-weight: 500;
      text-transform: uppercase;
    }
  }
  .aqwi-news-detail-img {
    max-width: 100%;
    height: 776px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .aqwi-news-detail-desc {
    font-weight: 400;
  }
  .aqwi-news-detail-date {
    color: $text_grey;
    font-size: 25px;
    line-height: 30px;
  }
}