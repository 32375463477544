footer {
  color: $text_white;
  line-height: 30px;
  padding: 120px 0;
  background-color: $dark;

  .footer_block {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: $text_grey;
    font-size: $small_text;

    a {
      color: $text_grey;
      text-decoration: none;
    }
  }

  .footer_title {
    margin-bottom: 50px;
  }

  .footer_contacts__logo {
    margin-top: auto;

    & img {
      max-width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 1400px) {
  footer {
    .footer_title {
      font-size: 26px;
    }

    .footer_contacts__content {
      font-size: 20px;
    }
  }
}

@media (max-width: 1200px) {
  footer {
    line-height: 28px;
    padding: 90px 0;

    .footer_title {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 992px) {
  footer {
    line-height: 26px;
    padding: 60px 0;

    .footer_title {
      font-size: 20px;
      margin-bottom: 25px;
    }

    .footer_contacts__content {
      font-size: 18px;
    }
  }
}

@media (max-width: 768px) {
  footer {
    line-height: 24px;
    padding: 40px 0;

    .footer_row {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 50px;

      & .col-4, & .col-8 {
        width: 100%;
        text-align: center;
      }
    }

    .footer_title {
      font-size: 18px;
      margin-bottom: 15px;
    }

    .footer_contacts__content {
      font-size: 16px;
    }

    .footer_contacts__logo {
      margin-top: 30px;
    }
  }
}

@media (max-width: 576px) {
  footer {
    padding: 30px 0;

    .footer_row {
      row-gap: 30px
    }

    .footer_contacts__logo {
      margin-top: 10px;
    }
  }
}
