.modal {
  &-dialog {
    max-width: 750px;
  }

  &-header,
  &-body,
  &-footer {
    padding: 0;
  }

  &-header {
    .btn-close {
      font-size: 22px;
      transition: all 0.3s linear;
    }
  }
}

#modalFeedback {
  .modal {
    &-content {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      line-height: initial;
      padding: 25px;
    }

    &-header,
    &-body,
    &-footer {
      padding: 0;
    }

    &-header,
    &-footer {
      border-top: none;
      border-bottom: none;
    }

    &-header {
      .modal-title {
        font-size: 24px;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      align-self: center;
      row-gap: 25px;
      width: 100%;
      max-width: 570px;

      textarea {
        scrollbar-width: none;

        &::-webkit-scrollbar {
          width: 1rem;
        }

        &::-webkit-scrollbar-track {
          border: 1px solid #ced4da;
          border-top-width: 0;
          border-right-width: 0;
          border-top-left-radius: 11px;
          border-top-right-radius: 11px
        }

        &::-webkit-scrollbar-thumb {
          background-color: darkgrey;
          border-radius: 11px;
        }
      }

      .form-control {
        font-size: 18px;
        padding: 12px 16px;
        border-radius: 11px;
      }
    }

    &-footer {
      display: flex;
      column-gap: 15px;

      .modal-btn {
        margin: 0;
      }

      .btn-success {
        width: 140px;
      }

      .btn-cancel {
        width: 110px;
      }
    }

    &-error {
      display: none;
      color: #dc3545;
      font-size: 12px;
      margin-top: 5px;

      &.visible {
        display: block;
      }
    }
  }
}

#modalNotify {
  .modal {
    &-content {
      padding: 25px;
      font-size: 25px;
    }

    &-header,
    &-body {
      padding: 0;
    }

    &-header {
      margin-bottom: 30px;
      border: none;
    }
  }
}

@media (max-width: 1400px) {
  .modal {
    &-dialog {
      max-width: 685px;
    }

    &-header {
      .btn-close {
        font-size: 20px;
      }
    }
  }

  #modalFeedback {
    .modal {
      &-body {
        max-width: 500px;
      }
    }
  }

  #modalNotify {
    .modal {
      &-content {
        padding: 25px 20px;
        font-size: 23px;
      }

      &-header {
        margin-bottom: 25px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .modal {
    &-dialog {
      max-width: 600px;
    }

    &-header {
      .btn-close {
        font-size: 18px;
      }
    }
  }

  #modalFeedback {
    .modal {
      &-header {
        .modal-title {
          font-size: 22px;
        }
      }

      &-body {
        max-width: 445px;

        .form-control {
          font-size: 16px;
          padding: 10px 14px;
        }
      }

      &-footer {
        .btn-success {
          width: 120px;
        }

        .btn-cancel {
          width: 90px;
        }
      }
    }
  }

  #modalNotify {
    .modal {
      &-content {
        font-size: 20px;
      }

      &-header {
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 768px) {
  .modal {
    &-dialog {
      max-width: 500px;
    }

    &-header {
      .btn-close {
        font-size: 16px;
      }
    }
  }

  #modalFeedback {
    .modal {
      &-content {
        row-gap: 25px;
      }

      &-header {
        .modal-title {
          font-size: 20px;
        }
      }

      &-body {
        row-gap: 20px;
        max-width: 100%;
      }

      &-footer {
        .modal-btn {
          font-size: 16px;
        }
      }
    }
  }

  #modalNotify {
    .modal {
      &-content {
        p {
          text-align: center;
          line-height: 30px;
        }
      }

      &-header {
        margin-bottom: 15px;
      }
    }
  }
}

@media (max-width: 576px) {
  .modal {
    &-dialog {
      max-width: 100%;
    }

    &-header {
      .btn-close {
        font-size: 16px;
      }
    }
  }

  #modalFeedback {
    .modal {
      &-body {
        row-gap: 15px;

        .form-control {
          padding: 8px 12px;
        }
      }
    }
  }
}
