.aqwi-cards-news-wrapper {
  .news-item {
    margin-bottom: 3rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .aqwi-card-news-item-content {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 457px;
    position: relative;
    display: flex;
    align-items: end;
    padding: 2rem;
    border-radius: $radius10;
    overflow: hidden;
    margin-bottom: 1rem;
  }

  .aqwi-card-news-desc {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #860b10;
    color: $white;
    z-index: 1;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 20px;
    font-weight: 500;
    padding: 2rem 1rem;
  }

  .aqwi-card-news-link {
    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
      z-index: 2;
    }
  }

  .aqwi-card-news-item-date {
    color: $text-grey;
    font-size: 25px;
    line-height: 30px;
  }

  .aqwi-group-buttons {
    margin-top: 3rem;
  }
}
