.name_page_wrapper .documents_page {
  background-image: url("../img/background-documents.jpg");
}

.aqwi-documents {
  .slick-dots {
    margin: 15px 0 5px 0;
  }

  &-slider {
    & .slick-slide {
      margin: 0 70px;
    }

    &-img {
      width: 100%;
      height: 650px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      a.glightbox {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;

        img {
          max-width: 100%;
          height: 100%;
          object-fit: fill;
          object-position: center;
        }
      }

      a.document-download {
        text-align: center;
        color: black;
        line-height: initial;
        margin-top: 10px;
        padding-bottom: 5px;
        transition: all 0.3s;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

@media (max-width: 1650px) {
  .aqwi-documents {
    &-slider {
      & .slick-slide {
        margin: 0 40px;
      }

      &-img {
        width: 380px;
        height: 590px;
      }
    }

    .pg-b-100 {
      padding-bottom: 75px;
    }
  }
}

@media (max-width: 1400px) {
  .aqwi-documents {
    &-slider {
      & .slick-slide {
        margin: 0 30px;
      }

      &-img {
        width: 334px;
        height: 540px;
      }
    }

    .pg-b-100 {
      padding-bottom: 50px;
    }
  }
}

@media (max-width: 1200px) {
  .aqwi-documents {
    &-slider {
      & .slick-slide {
        margin: 0 25px;
      }

      &-img {
        width: 280px;
        height: 485px;
      }
    }

    .pg-b-100 {
      padding-bottom: 50px;
    }
  }
}

@media (max-width: 992px) {
  .aqwi-documents {
    &-slider-img {
      width: 323px;
      height: 530px;
    }
  }
}

@media (max-width: 768px) {
  .aqwi-documents {
    .slick-dots {
      margin: 30px 0 5px 0;
    }

    &-slider {
      & .slick-slide {
        margin: 0 23px;
      }

      &-img {
        width: 235px;
        height: 400px;
      }
    }

    .pg-b-100 {
      padding-bottom: 25px;
    }
  }
}

@media (max-width: 576px) {
  .aqwi-documents {
    &-slider-img {
      width: 300px;
      height: 465px;
      margin: 0 auto;
    }

    .pg-b-100 {
      padding-bottom: 20px;
    }
  }
}
