// ПЕРЕОПРЕДЕЛЕНИЕ BOOTSTRAP
.offcanvas {
  &.offcanvas-end {
    width: 460px;
  }
}

.slick-dots {
  position: static;
  margin: 5px 0;

  li {
    &.slick-active {
      button {
        &::before {
          opacity: 0.5;
        }
      }
    }

    button {
      &::before {
        font-size: 70px;
        transition: all 0.3s;
        color: #999;
      }
    }
  }
}

.accordion-item {
  border: 0;
}

.accordion-button {
  padding: 50px 0;
}

.accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: inherit;
  border: 0;
  background-color: transparent;
  box-shadow: none;
}

.accordion-button::after {
  width: 82px;
  height: 82px;
  background-image: url("../img/accordion_icon.svg");
  background-size: contain;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../img/accordion_icon.svg");
  transform: rotate(45deg);
}

.accordion-button.arrow::after {
  width: 67px;
  height: 40px;
  background-image: url("../img/arrow.svg");
  background-size: contain;
}

.accordion-button.arrow:not(.collapsed)::after {
  background-image: url("../img/arrow.svg");
  transform: rotate(180deg);
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: $radius10;
}
