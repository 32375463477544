@mixin homeFont($fz, $lh) {
  font-size: $fz;
  line-height: $lh;
}

.main-page {
  main {
    min-height: 100vh;
  }

  .aqwi-main-title {
    h1 {
      color: $white;
      font-family: Jost, serif;
      font-size: 200px;
      font-weight: 500;
      line-height: 110px;
      letter-spacing: 3.5rem;
      margin-bottom: 2rem;

      span {
        display: block;
        font-size: 60px;
        font-weight: 400;
        letter-spacing: 1.5rem;
      }
    }
  }

  .aqwi-main {
    min-height: 100vh;
    padding: 104px 0;
    background-color: $dark;
    background-image: url("../img/aqwi-main.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;

    .aqwi-main-logo {
      margin-bottom: 330px;

      & img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .aqwi-main-desc {
    width: 670px;
    color: $white;
    @include homeFont(27px, 33px);
    font-weight: 300;

    p {
      margin: 0;
    }
  }

  .aqwi-home-section {
    padding: 100px 0;

    &.light {
      background-color: $white;
    }

    &.dark {
      background-color: $dark;
    }

    .aqwi-section-title {
      width: 50%;

      &.light {
        color: $white;
      }

      &.dark {
        color: $dark;
      }

      h2 {
        font-weight: 500;
        text-transform: uppercase;
        margin: 0;
      }
    }
  }

  .aqwi-key-directions {
    background-color: $dark;

    .aqwi-key-directions-cards {
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 100px;

      .aqwi-key-directions-card {
        position: relative;
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        width: 40%;
        color: $white;
        padding: 110px 2rem 130px;
        border-radius: $radius10;
        cursor: pointer;
        background-color: $dark;
        box-shadow: 4px 4px 81px rgba(255, 255, 255, 0.3);
        transition: .25s;

        &.active {
          color: $dark;
          background-color: $white;
        }

        &:nth-child(1) {
          left: 0;
          z-index: 4;
        }

        &:nth-child(2) {
          z-index: 3;
          margin-left: -20%;
        }

        &:nth-child(3) {
          z-index: 2;
          margin-left: -20%;
        }

        &:nth-child(4) {
          z-index: 1;
          margin-left: -20%;
        }

        .aqwi-key-directions-card-number {
          position: absolute;
          top: 30px;
          left: 30px;
          font-size: 70px;
        }

        .aqwi-key-directions-card-image {
          text-align: center;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .aqwi-key-directions-card-title {
          font-size: 35px;
          font-weight: 600;
          line-height: 41px;
          text-transform: uppercase;
          margin-bottom: 1.25rem;
        }

        .aqwi-key-directions-card-desc {
          @include homeFont(27px, 32px);
          font-weight: 400;
        }
      }
    }

    .aqwi-group-buttons {
      .btn-big {
        font-size: 40px;
        line-height: initial;
        padding: 2rem 5rem;
      }
    }
  }

  .aqwi-objects {
    .aqwi-object-tick {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
    }

    .aqwi-object-items {
      height: 650px;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }

    .aqwi-object-item {
      display: flex;
      color: $white;
      margin-bottom: 100px;
      padding: 6rem 3rem;
      border-radius: $radius10;
      background-color: $dark;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .aqwi-object-item-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;
      padding-right: 1.5rem;
    }

    .aqwi-object-item-img {
      width: 50%;
      border-radius: $radius10;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .aqwi-object-title {
      font-weight: 600;
      @include homeFont(46px, 55px);
      text-transform: uppercase;
      margin-bottom: 2rem;
    }

    .aqwi-object-desc {
      @include homeFont(28px, 33px);
    }

    .aqwi-object-item-link {
      position: relative;
      height: 648px;
      background-image: url("../img/object_more.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-clip: border-box;
      background-size: cover;

      a {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: $radius10;
        background-color: transparent;
      }
    }
  }

  .aqwi-customers {
    .aqwi-customers-slider {
      img {
        max-width: 300px;
        height: auto;
      }
    }

    .aqwi-customers-slider-wrap-img {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;
      padding: 1rem;
    }
  }

  .aqwi-gratitude {
    .aqwi-gratitude-slider {
      margin: 0 -20px;

      & .slick-slide {
        padding: 0 20px;
      }
    }

    .aqwi-gratitude-slider-item {
      display: flex;
      align-items: start;
      color: $white;
      padding: 6rem 3rem;
      border-radius: $radius10;
      background-color: $dark;
    }

    .aqwi-gratitude-slider-wrap-img-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 242px;
      height: 242px;
      margin-bottom: 1.25rem;
      border-radius: 50%;
      background-color: $white;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .aqwi-gratitude-slider-wrap-img {
      margin-right: 4rem;
    }

    .aqwi-gratitude-slider-img {
      display: flex;
      justify-content: center;
      text-align: center;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .aqwi-gratitude-slider-item-title {
      font-weight: 600;
      @include homeFont(46px, 55px);
      text-transform: uppercase;
      margin-bottom: 2rem;
    }

    .aqwi-gratitude-slider-item-desc {
      @include homeFont(28px, 33px);
    }
  }
}

@media (max-width: 1600px) {
  .main-page {
    .aqwi-main-title {
      h1 {
        font-size: 100px;
        line-height: 70px;
        letter-spacing: 2.5rem;
        margin-bottom: 2rem;

        span {
          display: block;
          font-size: 40px;
          font-weight: 400;
          letter-spacing: 1.2rem;
        }
      }
    }

    .aqwi-main-desc {
      width: 500px;
      font-size: 26px;
    }

    .aqwi-home-section {
      padding: 50px 0;

      .aqwi-section-title {
        width: 100%;
      }
    }

    .aqwi-key-directions {
      .aqwi-key-directions-cards {
        margin-bottom: 75px;

        .aqwi-key-directions-card {
          padding: 100px 2rem;

          .aqwi-key-directions-card-number {
            font-size: 60px;
          }

          .aqwi-key-directions-card-title {
            @include homeFont(28px, 30px);
          }

          .aqwi-key-directions-card-desc {
            @include homeFont(26px, 30px);
          }
        }
      }

      .aqwi-group-buttons {
        .btn-big {
          font-size: 36px;
          padding: 1rem 4rem;
        }
      }
    }

    .aqwi-objects {
      $home-title: 10px;

      .aqwi-object-title {
        @include homeFont(40px, 50px);
        margin-bottom: 1rem;
      }

      .aqwi-object-desc {
        @include homeFont(26px, 30px);
      }
    }

    .aqwi-gratitude {
      .aqwi-gratitude-slider-item-title {
        @include homeFont(40px, 50px);
        margin-bottom: 1rem;
      }

      .aqwi-gratitude-slider-item-desc {
        @include homeFont(26px, 30px);
      }
    }
  }
}

@media (max-width: 1400px) {
  .main-page {
    .aqwi-main-title {
      h1 {
        margin-bottom: 1.5rem;
      }
    }

    .aqwi-main-desc {
      @include homeFont(24px, 28px);
    }

    .aqwi-key-directions {
      .aqwi-key-directions-cards {
        margin-bottom: 50px;

        .aqwi-key-directions-card {
          padding: 100px 2rem 25px 2rem;

          .aqwi-key-directions-card-number {
            font-size: 50px;
          }

          .aqwi-key-directions-card-desc {
            @include homeFont(24px, 26px);
          }
        }
      }

      .aqwi-group-buttons {
        .btn-big {
          font-size: 32px;
        }
      }
    }

    .aqwi-objects {
      .aqwi-object-item-link {
        height: 550px;
      }

      .aqwi-object-title {
        @include homeFont(36px, 45px);
      }

      .aqwi-object-desc {
        @include homeFont(24px, 26px);
      }

      .aqwi-object-item-img {
        height: 300px;
      }
    }

    .aqwi-gratitude {
      .aqwi-gratitude-slider-wrap-img-icon {
        margin-bottom: 1rem;
      }

      .aqwi-gratitude-slider-wrap-img-icon {
        width: 210px;
        height: 210px;
      }

      .aqwi-gratitude-slider-img {
        img {
          width: 140px;
          height: 170px;
        }
      }

      .aqwi-gratitude-slider-item-title {
        @include homeFont(36px, 45px);
      }

      .aqwi-gratitude-slider-item-desc {
        @include homeFont(24px, 26px);
      }
    }
  }
}

@media (max-width: 1200px) {
  .main-page {
    .aqwi-main-title {
      h1 {
        margin-bottom: 1.25rem;
      }
    }

    .aqwi-main-desc {
      @include homeFont(22px, 26px);
    }

    .aqwi-key-directions {
      .aqwi-key-directions-cards {
        .aqwi-key-directions-card {
          padding: 60px 1.5rem 1rem 1.5rem;

          .aqwi-key-directions-card-number {
            top: 15px;
            left: 15px;
            font-size: 45px;
          }

          .aqwi-key-directions-card-title {
            @include homeFont(26px, 30px);
          }

          .aqwi-key-directions-card-desc {
            @include homeFont(22px, 26px);
          }
        }
      }

      .aqwi-group-buttons {
        .btn-big {
          font-size: 28px;
          padding: 1rem 3rem;
        }
      }
    }

    .aqwi-objects {
      .aqwi-object-tick {
        margin-top: 1rem;

        img {
          width: 40px;
          height: 25px;
        }
      }

      .aqwi-object-item {
        margin-bottom: 50px;
        padding: 4rem 3rem;
      }

      .aqwi-object-item-link {
        height: 450px;
      }

      .aqwi-object-title {
        @include homeFont(32px, 40px);
        margin-bottom: 1rem;
      }

      .aqwi-object-desc {
        @include homeFont(22px, 26px);
      }
    }

    .aqwi-gratitude {
      .aqwi-gratitude-slider-item {
        min-height: 462px;
        padding: 2rem;
      }

      .aqwi-gratitude-slider-wrap-img-icon {
        width: 180px;
        height: 180px;

        img {
          width: 110px;
          height: 110px;
        }
      }

      .aqwi-gratitude-slider-img {
        img {
          width: 120px;
          height: 150px;
        }
      }

      .aqwi-gratitude-slider-item-title {
        @include homeFont(32px, 40px);
      }

      .aqwi-gratitude-slider-item-desc {
        @include homeFont(22px, 26px);
      }
    }

  }
}

@media (max-width: 996px) {
  .main-page {
    .aqwi-main-title {
      h1 {
        margin-bottom: 1rem;
      }
    }

    .aqwi-main-desc {
      width: 450px;
      @include homeFont(20px, 26px);
    }

    .aqwi-home-section {
      padding: 30px 0;
    }

    .aqwi-key-directions {
      .aqwi-key-directions-cards {
        justify-content: center;
        flex-wrap: wrap;
        row-gap: 30px;
        margin-bottom: 30px;

        .aqwi-key-directions-card {
          width: 100%;
          padding: 50px 2rem 2rem;

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            z-index: 1;
            margin-left: 0;
          }

          .aqwi-key-directions-card-number {
            top: 25px;
            left: 25px;
            font-size: 36px;
          }

          .aqwi-key-directions-card-image {
            margin-bottom: 1rem;

            img {
              width: 60%;
            }
          }

          .aqwi-key-directions-card-title {
            margin-bottom: 1rem;
          }

          .aqwi-key-directions-card-desc {
            @include homeFont(20px, 26px);
          }
        }
      }

      .aqwi-group-buttons {
        .btn-big {
          font-size: 26px;
          padding: 1rem 2rem;
        }
      }
    }

    .aqwi-objects {
      .aqwi-object-item {
        flex-direction: column;
        align-items: center;
        row-gap: 15px;
        margin-bottom: 30px;
        padding: 3rem 2rem;
      }

      .aqwi-object-item-link {
        height: 350px;
      }

      .aqwi-object-item-text {
        width: 100%;
      }

      .aqwi-object-item-img {
        width: 80%;
        height: 80%
      }

      .aqwi-object-title {
        @include homeFont(28px, 35px);
      }

      .aqwi-object-desc {
        @include homeFont(20px, 26px);
      }
    }

    .aqwi-gratitude {
      .aqwi-gratitude-slider-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 30px;
        min-height: 715px;
        padding: 2rem 2rem 1rem;
      }

      .aqwi-gratitude-slider-wrap-img {
        margin: 0;
      }

      .aqwi-gratitude-slider-wrap-img-icon {
        width: 150px;
        height: 150px;
        margin-bottom: 0.75rem;

        img {
          width: 100px;
          height: 100px;
        }
      }

      .aqwi-gratitude-slider-img {
        img {
          width: 110px;
          height: 135px;
        }
      }

      .aqwi-gratitude-slider-item-title {
        @include homeFont(28px, 35px);
      }

      .aqwi-gratitude-slider-item-desc {
        @include homeFont(20px, 26px);
      }
    }
  }
}

@media (max-width: 768px) {
  .main-page {
    .aqwi-home-section {
      padding: 20px 0;
    }

    .aqwi-main-title {
      h1 {
        font-size: 90px;
        line-height: 65px;
        letter-spacing: 2rem;

        span {
          font-size: 32px;
          letter-spacing: 1rem;
        }
      }
    }

    .aqwi-main-desc {
      width: 100%;
      @include homeFont(18px, 24px);
    }

    .aqwi-key-directions {
      .aqwi-key-directions-cards {
        margin-bottom: 40px;

        .aqwi-key-directions-card {
          padding: 50px 1.5rem 1.5rem;

          .aqwi-key-directions-card-number {
            font-size: 32px;
          }

          .aqwi-key-directions-card-image {
            img {
              width: 70%;
            }
          }

          .aqwi-key-directions-card-title {
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 0.5rem;
          }

          .aqwi-key-directions-card-desc {
            @include homeFont(18px, 22px);
          }
        }
      }

      .aqwi-group-buttons {
        .btn-big {
          font-size: 24px;
          padding: 0.75rem 2rem;
        }
      }
    }

    .aqwi-objects {
      .aqwi-object-tick {
        img {
          width: 30px;
          height: 20px;
        }
      }

      .aqwi-object-item {
        padding: 1.5rem;
      }

      .aqwi-object-item-link {
        height: 250px;
      }

      .aqwi-object-title {
        @include homeFont(22px, 30px);
      }

      .aqwi-object-desc {
        @include homeFont(18px, 26px);
      }
    }

    .aqwi-gratitude {
      .aqwi-gratitude-slider-item {
        min-height: 690px;
        padding: 1.5rem 1rem 0.5rem;
      }

      .aqwi-gratitude-slider-item-title {
        @include homeFont(22px, 30px);
      }

      .aqwi-gratitude-slider-item-desc {
        @include homeFont(18px, 26px);
      }

      .aqwi-gratitude-slider-wrap-img-icon {
        width: 140px;
        height: 140px;

        img {
          width: 80px;
          height: 80px;
        }
      }

      .aqwi-gratitude-slider-img {
        img {
          width: 110px;
          height: 125px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .main-page {
    .aqwi-main-title {
      h1 {
        font-size: 60px;
        line-height: 50px;
        letter-spacing: 1.5rem;
        margin-bottom: 0.75rem;

        span {
          font-size: 22px;
          letter-spacing: 0.75rem;
        }
      }
    }

    .aqwi-key-directions {
      .aqwi-key-directions-cards {
        .aqwi-key-directions-card {
          .aqwi-key-directions-card-number {
            font-size: 28px;
          }

          .aqwi-key-directions-card-image {
            img {
              width: 90%;
            }
          }

          .aqwi-key-directions-card-title {
            font-size: 20px;
            line-height: 24px;
          }

          .aqwi-key-directions-card-desc {
            @include homeFont(16px, 22px);
          }
        }
      }

      .aqwi-group-buttons {
        .btn-big {
          font-size: 22px;
          padding: 0.5rem 1.5rem;
        }
      }
    }

    .aqwi-objects {
      .aqwi-object-item-link {
        height: 200px;
      }

      .aqwi-object-title {
        @include homeFont(20px, 26px);
      }

      .aqwi-object-desc {
        @include homeFont(16px, 22px);
      }

      .aqwi-object-item-img {
        width: 100%;
        height: 100%
      }
    }

    .aqwi-gratitude {
      .aqwi-gratitude-slider-item {
        min-height: 100%;
      }

      .aqwi-gratitude-slider-wrap-img-icon {
        width: 130px;
        height: 130px;
      }

      .aqwi-gratitude-slider-img {
        img {
          width: 100px;
          height: 115px;
        }
      }

      .aqwi-gratitude-slider-item-title {
        @include homeFont(20px, 26px);
      }

      .aqwi-gratitude-slider-item-desc {
        @include homeFont(16px, 22px);
      }
    }

    .aqwi-customers {
      .aqwi-customers-slider {
        img {
          max-width: 280px;
        }
      }

      .aqwi-customers-slider-wrap-img {
        justify-content: center;
      }
    }
  }
}
