.aqwi-btn {
  transition: 0.4s;

  &.btn-transparent {
    background-color: transparent;
    border: 2px solid $white;
    border-radius: $radius10;
    color: $white;

    &:hover {
      background-color: $white;
      color: $dark;
    }
  }

  &.btn-big {
    border: 0;
    border-radius: 11px;
  }

  &.btn-dark {
    background-color: $dark;

    &:hover {
      opacity: 0.9;
    }

    &:active {
      opacity: 0.8;
    }

    &.btn-big {
      font-size: 34px;
      line-height: 40px;
      padding: 2rem 2rem;
    }
  }

  &.btn-white {
    background-color: $white;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }

    &.btn-big {
      font-size: 43px;
      line-height: 50px;
      padding: 2rem 5rem;
    }
  }
}

.modal-btn {
  border: none;
  border-radius: 11px;
  color: #fff;
  font-size: 18px;
  padding: 10px 16px;
  transition: all 0.2s linear;

  &:active {
    opacity: 0.8;
  }

  &.btn-success {
    background: #830b0a;

    &:hover {
      background: #a90403;
    }
  }

  &.btn-cancel {
    background: #ccc;

    &:hover {
      background: #a2a2a2;
    }
  }
}

@media (max-width: 1650px) {
  .aqwi-btn {
    &.btn-dark {
      &.btn-big {
        padding: 1.5rem;
      }
    }
  }
}

@media (max-width: 1400px) {
  .aqwi-btn {
    &.btn-dark {
      &.btn-big {
        font-size: 32px;
        padding: 1.25rem;
      }
    }
  }
}

@media (max-width: 1200px) {
  .aqwi-btn {
    &.btn-dark {
      &.btn-big {
        font-size: 26px;
        padding: 1.25rem;
      }
    }
  }
}

@media (max-width: 992px) {
  .aqwi-btn {
    &.btn-dark {
      &.btn-big {
        font-size: 22px;
        padding: 1rem 1.25rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .aqwi-btn {
    &.btn-dark {
      &.btn-big {
        font-size: 20px;
        padding: 0.5rem 1rem;
      }
    }
  }
}

@media (max-width: 576px) {
  .aqwi-btn {
    &.btn-dark {
      &.btn-big {
        font-size: 18px;
        padding: 0.5rem 0.75rem;
      }
    }
  }
}
