$dark: #242424;
$white: #ffffff;
$text_white: #ffffff;
$text_grey: #A6A6A6;
$btn_white_text: #ffffff;

$small_text: 22px;
$main_text: 40px;
$main_line-height: 50px;
$radius10: 10px;

body {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
  font-family: Roboto, serif;
  font-size: $main_text;
  font-weight: normal;
  line-height: $main_line-height;
}

main {
  flex-grow: 1;
}

footer {
  flex-shrink: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.title {
  color: $text_white;
  font-weight: bold;
  text-transform: uppercase;
}

h3 {
  font-size: 28px;
}

.aqwi-pos-rel {
  position: relative;

  .aqwi-link-block {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    background-color: transparent;
  }
}

.aqwi-content-pg {
  padding: 100px 0;
}

.aqwi-breadcrumb {
  padding-top: 100px;

  a {
    display: inline-block;
    color: $text_grey;
    font-size: 50px;
    line-height: 60px;
    text-decoration: none;

    span::after {
      display: block;
      width: 0;
      height: 3px;
      margin-left: 0.25rem;
      content: "";
      background-color: $text_grey;
      transition: width .3s ease-in-out;
    }

    &:hover span::after,
    &:focus span::after {
      width: 100%;
    }
  }
}

.h2-size {
  font-size: 56px;
  line-height: 66px
}

.pg-b-100 {
  padding-bottom: 100px;
}

.mg-b-1 {
  margin-bottom: 1rem;
}

.mg-b-3 {
  margin-bottom: 3rem;
}

.mg-b-5 {
  margin-bottom: 5rem;
}

.aqwi-section-title {
  &.light {
    color: $white;
  }

  &.dark {
    color: $dark;
  }

  h2 {
    font-weight: 500;
    text-transform: uppercase;
    margin: 0;
  }
}

.aqwi-list {
  ul, ol {
    position: relative;
    padding-left: 2rem;

    li {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        display: inline-block;
        width: 10px;
        height: 10px;
        vertical-align: middle;
        margin-right: 1rem;
        border-radius: 50%;
        content: "";
        background-color: rgba(131, 11, 10, 1);
      }
    }

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 9px;
      border-radius: $radius10;
      content: "";
      background-color: rgba(131, 11, 10, 1);
    }

    &.line-type {
      padding-left: 3rem;

      li {
        position: relative;

        &::before {
          position: absolute;
          top: 5px;
          left: -64px;
          display: inline-block;
          width: 40px;
          height: 40px;
          content: "";
        }
      }
    }
  }
}

.hidden {
  display: none;
}

@media (min-width: 1650px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1596px;
  }
}

@media (max-width: 1650px) {
  body {
    font-size: 26px;
    line-height: 30px;
  }

  .h2-size {
    font-size: 40px;
    line-height: 50px;
  }

  .aqwi-breadcrumb {
    padding-top: 50px;

    a {
      font-size: 40px;
      line-height: 50px;
    }
  }

  .aqwi-content-pg {
    padding: 50px 0;
  }

  .aqwi-list {
    ul, ol {
      padding-left: 1.5rem;

      &::before {
        width: 6px;
      }
    }
  }
}

@media (max-width: 1400px) {
  body {
    font-size: 24px;
    line-height: 28px;
  }

  .mg-b-3 {
    margin-bottom: 2rem;
  }

  .mg-b-5 {
    margin-bottom: 4rem;
  }
}

@media (max-width: 1200px) {
  body {
    font-size: 22px;
    line-height: 26px;
  }

  .h2-size {
    font-size: 36px;
    line-height: 45px;
  }

  .mg-b-1 {
    margin-bottom: 0.75rem;
  }

  .mg-b-5 {
    margin-bottom: 3rem;
  }
}

@media (max-width: 992px) {
  body {
    font-size: 20px;
    line-height: 24px;
  }

  .h2-size {
    font-size: 32px;
    line-height: 40px;
  }

  .aqwi-breadcrumb {
    padding-top: 40px;

    a {
      font-size: 36px;
      line-height: 42px;
    }
  }

  .aqwi-content-pg {
    padding: 40px 0;
  }

  .mg-b-3 {
    margin-bottom: 1rem;
  }

  .mg-b-1 {
    margin-bottom: 0.5rem;
  }

  .aqwi-list {
    ul, ol {
      padding-left: 1rem;

      li {
        margin-bottom: 0.75rem;
      }
    }
  }
}

@media (max-width: 768px) {
  body {
    font-size: 18px;
  }

  .h2-size {
    font-size: 28px;
    line-height: 33px;
  }

  .aqwi-breadcrumb {
    padding-top: 30px;

    a {
      font-size: 28px;
      line-height: 35px;
    }
  }

  .aqwi-content-pg {
    padding: 30px 0;
  }

  .aqwi-list {
    ul, ol {
      li {
        margin-bottom: 0.5rem;
      }
    }
  }

  .mg-b-5 {
    margin-bottom: 2rem;
  }
}

@media (max-width: 576px) {
  body {
    font-size: 16px;
    line-height: 22px;
  }

  .h2-size {
    font-size: 22px;
    line-height: 26px;
  }

  .aqwi-breadcrumb {
    padding-top: 20px;

    a {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .aqwi-content-pg {
    padding: 20px 0;
  }

  .mg-b-1 {
    margin-bottom: 0.25rem;
  }

  .aqwi-list {
    ul, ol {
      padding-left: 0.5rem;

      &::before {
        width: 4px;
      }
    }
  }
}

@import "src/sass/block/header.scss";
@import "src/sass/block/fonts.scss";
@import "src/sass/block/home.scss";
@import "src/sass/block/name_page.scss";
@import "src/sass/services/service-detail.scss";
@import "src/sass/services/services.scss";
@import "src/sass/news/news-detail.scss";
@import "src/sass/news/news.scss";
@import "src/sass/company/company.scss";
@import "src/sass/objects/objects.scss";
@import "src/sass/contacts/contacts.scss";
@import "src/sass/documents/documents.scss";
@import "src/sass/block/accordion.scss";

@import "src/sass/block/buttons.scss";
@import "src/sass/block/scrollbar.scss";
@import "src/sass/block/custom-bootstrap.scss";

@import "src/sass/block/modal.scss";
@import "src/sass/block/sidebar.scss";
@import "src/sass/block/footer.scss";
