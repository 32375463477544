.name_page_wrapper .company_page {
  background-image: url("../img/background-company.jpg");
}

.aqwi-company {
  .aqwi-company-title {
    margin-bottom: 3rem;

    h2 {
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }

  .aqwi-company-section {
    margin-bottom: 100px;
  }

  .aqwi-lists-wrapper {
    .aqwi-list-title {
      font-weight: 500;
      margin-bottom: 2rem;
    }

    .aqwi-list-item {
      ul {
        position: relative;
        padding-left: 2rem;

        li {
          margin-bottom: 1rem;

          &:last-child {
            margin-bottom: 0;
          }

          &::before {
            display: inline-block;
            width: 10px;
            height: 10px;
            vertical-align: middle;
            margin-right: 1rem;
            border-radius: 50%;
            content: "";
            background-color: rgba(131, 11, 10, 1);
          }
        }

        &::before {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 9px;
          border-radius: $radius10;
          content: "";
          background-color: rgba(131, 11, 10, 1);
        }
      }
    }
  }

  .aqwi-company-img {
    display: flex;
    align-items: end;
    height: 776px;
    padding: 4em 0;
    background-image: url("../img/company_page.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .aqwi-company-img-text {
      color: $white;

      .title {
        text-transform: uppercase;
        margin-bottom: 2rem;
      }

      span {
        display: block;
      }
    }
  }

  .aqwi-company-utp {
    ul {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;

      li {
        position: relative;

        .list-content {
          display: flex;
          align-items: center;
          column-gap: 5rem;
        }

        span {
          display: block;
        }

        .list-icon {
          width: 75px;
          height: 75px;

          img {
            max-width: 100%;
            height: auto;
          }
        }

        .list-title {
          font-weight: 500;
        }
      }
    }
  }

  .aqwi-company-team {
    ul {
      li {
        display: flex;
        align-items: start;
        color: $white;
        margin-bottom: 3rem;
        padding: 4rem;
        border-radius: $radius10;
        background-color: $dark;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .person-img {
      margin-right: 3rem;
      border-radius: $radius10;
      overflow: hidden;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .person-content {
      width: 100%;
      overflow: auto;
    }

    .person-name {
      font-size: 44px;
      font-weight: 600;
      line-height: 55px;
    }

    .person-post {
      font-weight: 400;
    }

    .person-info {
      font-weight: 400;
      margin-top: 4rem;

      & p {
        margin-bottom: 0.25rem;
      }
    }
  }

  .aqwi-group-buttons {
    margin-top: 3rem;
  }

  .aqwi-company-vacancies {
    .accordion-item {
      .accordion-header {
        button {
          padding-right: 3rem;
          padding-left: 3rem;
        }
      }

      .accordion-body {
        font-size: 50px;
        line-height: 60px;
        padding: 0 3rem 3rem;

        .aqwi-accordion-lists {
          margin-bottom: 3rem;

          .aqwi-accordion-title {
            margin-bottom: 0.5rem;
          }

          .aqwi-accordion-list {
            ul {
              padding-left: 0;

              li {
                margin-bottom: 0.25rem;
              }
            }
          }
        }

        .aqwi-vacancies-accordion-email {
          margin-top: 4rem;
        }
      }
    }
  }
}

@media (max-width: 1650px) {
  .aqwi-company {
    .aqwi-company-title {
      margin-bottom: 2rem;
    }

    .aqwi-lists-wrapper {
      .aqwi-list-item {
        ul {
          &::before {
            width: 6px;
          }
        }
      }
    }

    .aqwi-company-img {
      height: 675px;

      .aqwi-company-img-text {
        .title {
          margin-bottom: 1rem;
        }
      }
    }

    .aqwi-company-utp {
      ul {
        li {
          .list-content {
            column-gap: 3rem;
          }

          .list-icon {
            width: 60px;
            height: 60px;

            img {
              width: 60px;
              height: 60px;
            }
          }
        }
      }
    }

    .aqwi-company-team {
      .person-img {
        width: 600px
      }

      .person-name {
        font-size: 32px;
        line-height: 40px;
      }
    }

    .aqwi-company-vacancies {
      .accordion-item {
        .accordion-header {
          button {
            &::after {
              width: 60px;
            }
          }
        }

        .accordion-body {
          font-size: 36px;
          line-height: 42px;

          .aqwi-accordion-lists {
            margin-bottom: 2rem;
          }

          .aqwi-vacancies-accordion-email {
            margin-top: 3rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .aqwi-company {
    .aqwi-lists-wrapper {
      .aqwi-list-title {
        margin-bottom: 0.75rem;
      }

      .aqwi-list-item {
        ul {
          padding-left: 1.5rem;

          li {
            margin-bottom: 0.75rem;
          }
        }
      }
    }

    .aqwi-company-utp {
      ul {
        row-gap: 1.5rem;

        li {
          .list-content {
            column-gap: 2rem;
          }

          .list-icon {
            width: 50px;
            height: 50px;

            img {
              width: 50px;
              max-width: none;
              height: 50px;
            }
          }
        }
      }
    }

    .aqwi-company-team {
      ul {
        li {
          padding: 3rem;
        }
      }

      .person-img {
        width: auto;
      }

      .person-name {
        font-size: 26px;
        line-height: 33px;
      }
    }

    .aqwi-company-vacancies {
      .accordion-item {
        .accordion-header {
          button {
            &::after {
              width: 45px;
              height: 30px;
            }
          }
        }

        .accordion-body {
          font-size: 32px;
          line-height: 36px;

          .aqwi-accordion-lists {
            margin-bottom: 1.75rem;
          }

          .aqwi-vacancies-accordion-email {
            margin-top: 2.75rem;
          }
        }
      }
    }
  }

}

@media (max-width: 992px) {
  .aqwi-company {
    .aqwi-lists-wrapper {
      .aqwi-list-title {
        margin-bottom: 0.5rem;
      }
    }

    .aqwi-company-team {
      ul {
        li {
          padding: 2rem;
        }
      }

      .person-name {
        font-size: 22px;
        line-height: 26px;
      }
    }

    .aqwi-company-section {
      margin-bottom: 50px;
    }

    .aqwi-lists-wrapper {
      .aqwi-list-item {
        ul {
          li {
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    .aqwi-company-img {
      height: 550px;
    }

    .aqwi-company-vacancies {
      .accordion-item {
        .accordion-header {
          button {
            padding-right: 2rem;
            padding-left: 2rem;

            &::after {
              width: 40px;
              height: 25px;
            }
          }
        }

        .accordion-body {
          font-size: 20px;
          line-height: 24px;
          padding: 0 2rem 2rem;

          .aqwi-accordion-lists {
            margin-bottom: 1rem;

            .aqwi-accordion-title {
              margin-bottom: 0.25rem;
            }
          }

          .aqwi-vacancies-accordion-email {
            margin-top: 1.5rem;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .aqwi-company {
    .aqwi-company-title {
      margin-bottom: 1rem;
    }

    .aqwi-lists-wrapper {
      .aqwi-list-item {
        ul {
          padding-left: 1rem;

          li {
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    .aqwi-company-img {
      height: 450px;

      .aqwi-company-img-text {
        .title {
          margin-bottom: 0.5rem;
        }
      }
    }

    .aqwi-company-utp {
      ul {
        row-gap: 1rem;

        li {
          .list-content {
            column-gap: 2rem;
          }

          .list-icon {
            width: 40px;
            height: 40px;

            img {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }

    .aqwi-company-team {
      ul {
        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 25px;
          text-align: center;
          margin-bottom: 2rem;
          padding: 2rem 1.5rem;

          .person-img {
            margin: 0;

            & img {
              width: 350px;
            }
          }

          .person-name {
            font-size: 22px;
            line-height: 26px;
          }

          .person-info {
            margin-top: 1rem;
          }
        }
      }
    }

    .aqwi-group-buttons {
      margin-top: 2rem;
    }

    .aqwi-company-vacancies {
      .accordion-item {
        .accordion-header {
          button {
            font-size: 20px;
            padding-right: 2rem;
            padding-left: 2rem;

            &::after {
              width: 35px;
              height: 20px;
            }
          }
        }

        .accordion-body {
          font-size: 18px;
          line-height: 26px;

          .aqwi-accordion-lists {
            .aqwi-accordion-title {
              margin-bottom: 0;
            }

            .aqwi-accordion-list {
              ul {
                li {
                  margin-bottom: 0;

                  &::before {
                    margin-right: 0.5rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .aqwi-company {
    .aqwi-lists-wrapper {
      .aqwi-list-item {
        ul {
          &::before {
            width: 4px;
          }
        }
      }
    }

    .aqwi-company-section {
      margin-bottom: 35px;
    }

    .aqwi-company-team {
      ul {
        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 15px;
          text-align: center;
          margin-bottom: 1.5rem;

          .person-img {
            & img {
              width: 250px;
            }
          }

          .person-name {
            font-size: 20px;
          }

          .person-info {
            margin-top: 0.75rem;
          }
        }
      }
    }

    .aqwi-group-buttons {
      margin-top: 1.5rem;
    }

    .aqwi-company-vacancies {
      .accordion-item {
        .accordion-header {
          button {
            font-size: 18px;
            padding-right: 1.5rem;
            padding-left: 1.5rem;

            &::after {
              width: 30px;
              height: 16px;
            }
          }
        }

        .accordion-body {
          font-size: 16px;
          line-height: 24px;
          padding: 0 1.5rem 1.5rem;
        }
      }
    }
  }
}
