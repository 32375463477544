.name_page_wrapper .services_detail {
  background-image: url("../img/background-services.jpg");
}

.detail-it-desc {
  p {
    margin: 0;
  }
}

.aqwi-cards {
  .aqwi-servive-detail-title {
    h2 {
      font-weight: 500;
      text-transform: uppercase;
      margin: 0;
    }
  }

  .aqwi-service-detail-image {
    img {
      width: 100%;
      max-width: 100%;
      height: auto;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .aqwi-service-detail-content {
    .accordion {
      &-body {
        padding-top: 0;
      }
    }

    .detail-group-buttons {
      margin-top: 9rem;
    }

    .aqwi-accordion-desc {
      margin-bottom: 2.5rem;

      p {
        margin: 0;
      }
    }
  }
}

@media (max-width: 1650px) {
  .aqwi-cards {
    .aqwi-service-detail-content {
      .detail-group-buttons {
        margin-top: 6rem;
      }
    }
  }
}

@media (max-width: 1400px) {
  .aqwi-cards {
    .aqwi-service-detail-content {
      .detail-group-buttons {
        margin-top: 4rem;
      }

      .aqwi-accordion-desc {
        margin-bottom: 2rem;
      }
    }
  }
}

@media (max-width: 1200px) {
  .aqwi-cards {
    .aqwi-service-detail-content {
      .detail-group-buttons {
        margin-top: 3rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .aqwi-cards {
    .aqwi-service-detail-content {
      .detail-group-buttons {
        margin-top: 2rem;
      }

      .aqwi-accordion-desc {
        margin-bottom: 1rem;
      }
    }
  }
}

